import {
    GET_SITE_DATA,
    UPDATE_SITE
} from './actionTypes';
  
export const getSiteData = (subscription_id, onSuccess, onFail) => ({
    type: GET_SITE_DATA,
    payload: {
      subscription_id
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const updateSite = (subscription_id, data, onSuccess, onFail) => ({
  type: UPDATE_SITE,
  payload: {
    subscription_id,
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});