import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import './css/index.css';
import { Provider } from "react-redux";
import store from "./config/store";
import DevTools from './components/utils/DevTools';
if (process.env.NODE_ENV !== 'production') {
  // require('dotenv').config()
  // console.log("configuring");
  //console.log("REACT_APP_LOGOUT_URL", process.env.REACT_APP_LOGOUT_URL)
  //console.log("REACT_APP_SERVER_URL", process.env.REACT_APP_SERVER_URL);
}
ReactDOM.render(
    <Provider store={store}>
      <React.Fragment>
        <App/>
        {
          process.env.NODE_ENV !== 'production' ? 
          <DevTools /> :
          null
        }
      </React.Fragment>
    </Provider>, document.getElementById('root')
  );
  

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
