import React from 'react';
import { connect } from "react-redux";
import { getAccountData } from '../actions/accountActions';
import { withRouter } from 'react-router-dom';

class Session extends React.Component {
  componentDidMount() {
    const onSuccess = () => this.onSuccess();
    this.props.getAccountData(null, onSuccess);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customer) {
      if (nextProps.customer.is_admin) {
        this.props.history.push('/admin_dashboard');
      } else {
        this.props.history.push('/user_dashboard');
      }
    }
  }

  render() {
    return (
      <div>Loading...</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.data.mongoCustomer
  }
}

const mapDispatchToProps= (dispatch, ownProps) => {
  return {
    getAccountData: (data, onSuccess, onFail) => {
      dispatch(getAccountData(data, onSuccess, onFail))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Session));
