import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
// import { GetFormattedDate } from '../../utils/dateUtils';
import { arrayFromKeyedObject } from '../../utils/dataUtils';
import { updateCustomerPayment, getInvoices, getStripeCustomer } from '../../actions/customerActions';
import { getSiteData, updateSite } from '../../actions/siteActions'
import { cancelSubscription, updateMongoCustomer } from '../../actions/accountActions'
import {
  Page,
  Toolbar,
  ToolbarButton,
  Icon,
  List,
  ListItem,
  Dialog,
  Button,
  Input,
  ProgressCircular
} from 'react-onsenui';
import StripeWrapper from '../account/StripeWrapper';
import LicenseDetails from '../license/LicenseDetails';

class CustomerDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editModalOpen: false,
      confirmCancelModalOpen: false,
      siteName: '',
      stripeFormActive: false,
      stripeUpdating: false,
      hideMainPane: false,
      paymentHistoryLoading: false,
      openPaymentModal: false,
      paymentHistoryModalOpen: false,
      licenseSelected: false,
      selectedLicenseId: null,
      selectedInvoice: {}
    }
  }

  componentDidMount() {
    if (this.props.selectedCustomer.stripe_id){
      this.props.getStripeCustomer({stripe_id: this.props.selectedCustomer.stripe_id});
    }
  }

  componentDidUpdate() {
  }

  openEditModal = () => {
    this.setState({
      editModalOpen: true,
      confirmCancelModalOpen: false
    })
  }

  closeAllModals = () => {
    this.setState({
      editModalOpen: false,
      paymentHistoryModalOpen: false,
      confirmCancelModalOpen: false
    })
  }

  openConfirmCancelModal = () => {
    this.setState({
      editModalOpen: false,
      confirmCancelModalOpen: true
    })
  }

  setupEditForm = () => {
    this.setState({
      customerName: this.props.selectedCustomer.primary_contact.name || null,
      customerEmail: this.props.selectedCustomer.primary_contact.email || null,
      customerCompany: this.props.selectedCustomer.company || null,
      customerIsAdmin: this.props.selectedCustomer.is_admin || false
    })
  }

  submitEditCustomer = () => {
    this.closeAllModals();
    // this.props.updateSite(this.props.selectedCustomer.id, { name: this.state.siteName })
  }

  updateMongoCustomer = () => {
    this.closeAllModals();
    const data = {
      id: this.props.selectedCustomer._id,
      admin_panel_update: true,
      name: this.state.customerName,
      company: this.state.customerCompany,
      email: this.state.customerEmail
    }
    let self = false;
    if (_.get(this.props, 'mongoCustomer._id', null) === data.id){
      self = true;
    }
    this.props.updateMongoCustomer(self, data, () => {
      this.props.showToast("Info Updated")
    })
  }

  cancelSubscription = () => {
    this.closeAllModals();
    this.props.cancelSubscription(this.props.selectedCustomer.id)
  }

  cancelStripeUpdate = () => {
    this.setState({
      stripeFormActive: false
    })
  }

  updateCustomerPayment = (token) => {
    if (token && token.id){
      const handleDone = () => { this.setState({ stripeUpdating: false }) }
      const data = { token, stripeCustomerId: this.props.stripeCustomer.id };
      this.props.updateCustomerPayment(data, handleDone, handleDone);
    }
    this.setState({
      stripeFormActive: false,
      stripeUpdating: true
    })
  }

  getSubscriptions = () => {
    const rowStyle = {
      height: 62
    }
    return this.props.subscriptions.map((sub, i) => {
      return (
        <ListItem onClick={() => this.handleSelectLicense(sub)} key={i} modifier="longdivider">
          <div style={rowStyle} className="left" > {sub.id} </div>
          <div style={rowStyle} className="right" > {sub.status} </div>
        </ListItem>
      );
    })
  }

  openPaymentHistory = () => {
    this.setState({ hideMainPane: true, paymentHistoryLoading: true });
    const onSuccess = () => this.finishedLoadingPaymentHistory();
    this.props.getInvoices({ stripeCustomerId: this.props.stripeCustomer.id }, onSuccess);
  }

  openPaymentHistoryModal = (invoice) => {
    this.setState({
      paymentHistoryModalOpen: true,
      selectedInvoice: invoice
    });
  }

  handleSelectLicense = (license) => {
    this.setState({
      hideMainPane: true,
      licenseSelected: true,
      selectedLicenseId: license.id
    });
  }

  finishedLoadingPaymentHistory = () => {
    this.setState({
      paymentHistoryLoading: false
    });
  }

  getInvoices = () => {
    const rowStyle = {
      height: 62
    }
    return this.props.invoices.map((inv, i) => {
      return (
        <ListItem onClick={() => this.openPaymentHistoryModal(inv)} key={i} modifier="longdivider">
          <div style={rowStyle} className="left" > {inv.account_name} </div>
          <div style={rowStyle} className="right" > {inv.total/100} {inv.currency.toUpperCase()} </div>
        </ListItem>
      );
    });
  }

  getSources = () => {
    const rowStyle = {
      height: 62
    }
    const sources = _.get(this.props.stripeCustomer, 'sources.data', []);
    if (sources.length) {
      return sources.map((s, i) => {
        return (
          <ListItem key={i} modifier="longdivider">
            <div style={rowStyle} className="left" > {s.brand} </div>
            <div style={rowStyle} className="center" > {s.last4} </div>
            <div style={rowStyle} className="right" > <a href="/">Remove</a> </div>
          </ListItem>
        )
      });
    }
  }

  render() {
    if (this.props.selectedCustomer === null || this.props.selectedCustomer === undefined) {
      return (
        <Page></Page>
      )
    }
    
    const rowStyle = {
      height: 62
    }

    // const renewal_date = new Date(this.props.selectedCustomer.current_period_end * 1000)
    if (this.props.selectedCustomer !== null) {
      // const name = _.get(this.props.selectedCustomer, 'primary_contact.name', '');
      return (
        <React.Fragment>
          <Dialog onCancel={() => { this.closeAllModals() }}
            isOpen={this.state.paymentHistoryModalOpen}
            style={{ height: '100vh' }}
            animation={'none'}
            cancelable>
              <div style={{ minWidth: '60vw', height: 400 }}>
                <div style={{ textAlign: 'left' }}>
                  <ToolbarButton>
                    <Icon onClick={() => { this.closeAllModals() }} icon="md-close" size={24} />
                  </ToolbarButton>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <b>{_.get(this.state, 'selectedInvoice.id', null)}</b>
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <b>Account Name </b>{_.get(this.state, 'selectedInvoice.account_name', null)}
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <b>Total </b>{_.get(this.state, 'selectedInvoice.total', 0)/100} {_.get(this.state, 'selectedInvoice.currency', "").toUpperCase()}
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <a rel="noopener noreferrer" target="_blank" href={_.get(this.state, 'selectedInvoice.hosted_invoice_url', '#')}>See hosted invoice</a>
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <b>Status </b>{_.get(this.state, 'selectedInvoice.status', "").toUpperCase()}
                  </div>
                </div>
              </div>
          </Dialog>
          {/* <Dialog onCancel={() => { this.closeAllModals() }}
            onPreShow={this.setupEditForm}
            isOpen={this.state.editSubscriptionModalOpen}
            style={{ height: '100vh' }}
            animation={'none'}
            cancelable>
          </Dialog> */}
          <Dialog onCancel={() => { this.closeAllModals() }}
            onPreShow={this.setupEditForm}
            isOpen={this.state.editModalOpen}
            style={{ height: '100vh' }}
            animation={'none'}
            cancelable>
            <div style={{ minWidth: '60vw', height: 400 }}>
              <Page>
                <div style={{ textAlign: 'left' }}>
                  <ToolbarButton>
                    <Icon style={{color: "#45535b"}} onClick={() => { this.closeAllModals() }} icon="md-close" size={24} />
                  </ToolbarButton>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <div style={{ marginTop: 32, marginBottom: 32 }}>
                    Edit customer {this.props.selectedCustomer.stripe_id}
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <Input
                      value={this.state.customerName} float
                      onChange={(event) => { this.setState({ customerName: event.target.value }) }}
                      modifier='material'
                      placeholder='Customer Name' />
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <Input
                      value={this.state.customerEmail} float
                      onChange={(event) => { this.setState({ customerEmail: event.target.value }) }}
                      modifier='material'
                      placeholder='Customer Email' />
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16 }}>
                    <Input
                      value={this.state.customerCompany} float
                      onChange={(event) => { this.setState({ customerCompany: event.target.value }) }}
                      modifier='material'
                      placeholder='Customer Company' />
                  </div>
                  <div style={{ marginTop: 0, marginBottom: 16, width: '100%', textAlign: 'center' }}>
                    <Button onClick={() => this.updateMongoCustomer()} className="btn-primary">
                      Submit
                    </Button>
                  </div>
                  <br /><br />
                </div>
              </Page>
            </div>
          </Dialog>
          {
            this.state.hideMainPane ?
            
            <Page>
              {
                this.state.paymentHistoryLoading ?
                <React.Fragment>
                  <Toolbar>
                    <div className="center">
                      <b>Payment History</b>
                    </div>
                  </Toolbar>
                  <br /><br />
                  <List>
                    <ListItem modifier="longdivider">
                      <div className="center">
                        <ProgressCircular indeterminate />
                      </div>
                    </ListItem>
                  </List>
                </React.Fragment>
                :
                !this.state.licenseSelected ?
                  <React.Fragment>
                    <Toolbar>
                      <div className="center">
                        <b>Payment History</b>
                      </div>
                    </Toolbar>
                    <br /><br />
                    <List>
                      {this.getInvoices()}
                    </List>
                  </React.Fragment>
                : null                
              }
              {
                this.state.licenseSelected ?
                <React.Fragment>
                  <LicenseDetails
                    updateSite={(subscription_id, data) => {this.props.updateSite(subscription_id, data, () => this.props.showToast("Site's Information has been updated successfully"))}}
                    getSiteData={(subscription_id) => {this.props.getSiteData(subscription_id)}}
                    cancelSubscription={(subscription_id) => {this.props.cancelSubscription(subscription_id, () => this.props.showToast("Subscription has been cancelled successfully"))}}
                    closeRight={this.state.closeRight}
                    selectedLicenseId={this.state.selectedLicenseId}
                  />
                </React.Fragment>
                :
                null
              }
            </Page>
            :
            <Page>
              <Toolbar>
                <div className="center">
                  <b>{_.get(this.props.selectedCustomer, 'stripe_id', '')}</b>
                </div>
                <div className="right">
                  <ToolbarButton>
                    <Icon style={{color: "#45535b"}} onClick={() => { this.setState({ editModalOpen: true }) }} icon="md-edit" size={24} />
                  </ToolbarButton>
                </div>
              </Toolbar>
              <br /><br />
              <List>
                <ListItem modifier="longdivider">
                  <div style={rowStyle} className="left" >Name</div>
                  <div style={rowStyle} className="right" > {_.get(this.props.selectedCustomer, 'primary_contact.name', '')}</div>
                </ListItem>
                <ListItem modifier="longdivider">
                  <div style={rowStyle} className="left" >Email</div>
                  <div style={rowStyle} className="right" > {_.get(this.props.selectedCustomer, 'primary_contact.email', '')} </div>
                </ListItem>
                <ListItem modifier="longdivider">
                  <div style={rowStyle} className="left" >Company</div>
                  <div style={rowStyle} className="right" > {this.props.selectedCustomer.company} </div>
                </ListItem>
                <ListItem style={{ textAlign: "center" }} modifier="longdivider">
                  <div style={{ width:"100%", textAlign: "center" }}><b>Payment Methods</b></div>
                </ListItem>
                <List>
                  {this.state.stripeFormActive ? (
                    <StripeWrapper
                      updatePaymentMethod={this.updateCustomerPayment}
                      cancelStripeUpdate={this.cancelStripeUpdate}
                    />
                  ) : null}
                  {!this.state.stripeUpdating && !this.state.stripeFormActive ?
                    <ListItem modifier="longdivider">
                      <div className="left">
                        Payment Method: CC -{" "}
                        {_.get(this.props, "defaultPaymentMethod.last4", "None")}
                      </div>
                      <div className="right">
                        <Button
                          className="btn-primary full-width"
                          style={{color: 'white'}}
                          onClick={() => this.setState({ stripeFormActive: true })}
                        >
                          Change
                        </Button>
                      </div>
                    </ListItem>
                    :
                    null
                  }
                  {this.state.stripeUpdating ?
                  <ListItem modifier="longdivider">
                    <div className="left">
                      <ProgressCircular indeterminate />
                    </div>
                    <div className="right">
                      <Button disabled modifier="outline">
                        Change
                      </Button>
                    </div>
                  </ListItem>
                : null}
                </List>
                <ListItem style={{ textAlign: "center" }} modifier="longdivider">
                  <div style={{ width:"100%", textAlign: "center" }}><b>Subscriptions</b></div>
                </ListItem>
                <List>
                  {this.getSubscriptions()}
                </List>
                <ListItem style={{ textAlign: "center" }} modifier="longdivider">
                  <div onClick={() => this.openPaymentHistory() } style={{ width:"100%", textAlign: "center" }}><b>Payment History >></b></div>
                </ListItem>
              </List>
            </Page>
          }
        </React.Fragment>
      )
    }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCustomerPayment: (data, onSuccess, onFail) => {
      dispatch(updateCustomerPayment(data, onSuccess, onFail))
    },
    getInvoices: (data, onSuccess, onFail) => {
      dispatch(getInvoices(data, onSuccess, onFail))
    },
    getSiteData: (subscription_id, onSuccess, onFail) => {
      dispatch(getSiteData(subscription_id, onSuccess, onFail))
    },
    updateSite: (subscription_id, data, onSuccess, onFail) => {
      dispatch(updateSite(subscription_id, data, onSuccess, onFail))
    },
    cancelSubscription: (subscription_id, onSuccess, onFail) => {
      dispatch(cancelSubscription(subscription_id, onSuccess, onFail))
    },
    updateMongoCustomer: (self, data, onSuccess, onFail) => {
      dispatch(updateMongoCustomer(self, data, onSuccess, onFail))
    },
    getStripeCustomer: (data, onSuccess, onFail) => {
      dispatch(getStripeCustomer(data, onSuccess, onFail))  
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const selectedCustomer = state.data.customers[ownProps.selectedCustomerId];
  let stripeCustomer = null;
  if (selectedCustomer && selectedCustomer.stripe_id){
    stripeCustomer = state.data.stripe_customers[selectedCustomer.stripe_id];
  }
  const subscriptions = arrayFromKeyedObject(state.data.subscriptions).filter(sub => sub.customer === selectedCustomer.stripe_id)
  const defaultPaymentMethods = _.get(stripeCustomer, 'sources.data', []);
  const defaultPaymentMethod = defaultPaymentMethods.find(s => s.id === _.get(stripeCustomer, 'default_source', null));
  const invoices = arrayFromKeyedObject(state.data.invoices)
  return {
    selectedCustomer,
    subscriptions,
    stripeCustomer,
    defaultPaymentMethod,
    invoices,
    mongoCustomer: state.data.mongoCustomer
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);