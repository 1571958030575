import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash'
import StripeWrapper from './StripeWrapper'
import { updateMongoCustomer, updatePaymentMethod, passwordReset } from '../../actions/accountActions'
// import logo from './logo.svg';
import {
  Page,
  List,
  ListItem,
  Input,
  Button,
  ProgressCircular,
  SplitterContent,
  SplitterSide
} from 'react-onsenui';

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closeRight: false,
      name: _.get(this.props, 'mongoCustomer.primary_contact.name', ''),
      email: 'test@example.com',
      existingPassword: '',
      stripeFormActive: false,
      stripeUpdating: false
    }
  }

  updateMongoCustomer = () => {
    if (!this.state.name || this.state.name === ''){
      return;
    }
    this.props.updateMongoCustomer({name: this.state.name}, () => {
      this.props.showToast("Info Updated")
    })
  }

  cancelStripeUpdate = () => {
    this.setState({
      stripeFormActive: false
    })
  }

  submitPasswordReset = () => {
    //if (existingPassword && existingPassword.length >= 5){
      this.props.passwordReset( 
        () => {this.props.showToast("Password request sent, check your email")},
        () => {this.props.showToast("An error occured resetting your password.")}
      )
      this.setState({
        existingPassword: ''
      })
    //}
  }

  updatePaymentMethod = (token) => {
    console.log("token in Account Details", token);
    if (token && token.id){
      const handleDone = () => {this.setState({stripeUpdating: false})}
      this.props.updatePaymentMethod(token, handleDone, handleDone);
    }
    this.setState({
      stripeFormActive: false,
      stripeUpdating: true
    })
  }

  renderAccountDetails = () => {
    return (
      <Page>
        <List>
          <ListItem modifier="longdivider">
            Email: {this.state.email}
          </ListItem>
          <ListItem modifier="longdivider">
            <Input
              value={this.state.name}
              float
              onChange={event => {
                this.setState({ name: event.target.value });
              }}
              modifier="material"
              placeholder="Full Name"
            />
          </ListItem>
          <ListItem modifier="longdivider">
            <div className="right" style={{
              width: "6%"
            }}>
              <Button
                className="btn-primary full-width"
                onClick={() => this.updateMongoCustomer()}
              >
                Submit
              </Button>
            </div>
          </ListItem>
          <ListItem modifier="longdivider">
            <div className="left">
              Request a password reset
            </div>
            <div className="right" style={{
              width: "6%"
            }}>
              <Button
                className="btn-primary full-width"
                onClick={() =>
                  this.submitPasswordReset()
                }
              >
                Reset
              </Button>
            </div>
          </ListItem>

          {this.state.stripeFormActive ? (
            <StripeWrapper
              updatePaymentMethod={this.updatePaymentMethod}
              cancelStripeUpdate={this.cancelStripeUpdate}
            />
          ) : null}
          {!this.state.stripeUpdating && !this.state.stripeFormActive ? 
            <ListItem modifier="longdivider">
              <div className="left">
                Payment Method: CC -{" "}
                {_.get(this.props, "defaultPaymentMethod.last4", "None")}
              </div>
              <div className="right" style={{
                width: "6%"
              }}>
                <Button
                  onClick={() => this.setState({ stripeFormActive: true })}
                  className="btn-primary full-width"
                >
                  Change
                </Button>
              </div>
            </ListItem>
           : 
           null}
           {this.state.stripeUpdating ?
           
            <ListItem modifier="longdivider">
              <div className="left">
                <ProgressCircular indeterminate />
              </div>
              <div className="right">
                <Button disabled modifier="outline">
                  Change
                </Button>
              </div>
            </ListItem>
          : null}
        </List>
      </Page>
    );
  }

  render() {
    return (
      <React.Fragment>
        <SplitterContent >
          {this.renderAccountDetails()}
        </SplitterContent>
        <SplitterSide
          side="right"
          width={0}
          collapse={false}
          isOpen={true}
        >
          <Page></Page>
        </SplitterSide>
      </React.Fragment>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    logged_in: state.data.logged_in,
    auth0Profile: state.data.auth0Profile,
    auth0User: state.data.auth0User,
    mongoCustomer: state.data.mongoCustomer,
    defaultPaymentMethod: state.data.defaultPaymentMethod
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateMongoCustomer: (data, onSuccess, onFail) => {
      dispatch(updateMongoCustomer(true, data, onSuccess, onFail))
    },
    updatePaymentMethod: (data, onSuccess, onFail) => {
      dispatch(updatePaymentMethod(data, onSuccess, onFail))
    },
    passwordReset: (onSuccess, onFail) => {
      dispatch(passwordReset(onSuccess, onFail))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
