import { GET_CUSTOMERS, UPDATE_CUSTOMER_PAYMENT, GET_INVOICES, GET_STRIPE_CUSTOMER, UPDATE_GOOGLE_GROUPS, CLEAR_GOOGLE_GROUPS } from "./actionTypes";

export const getCustomers = (data, onSuccess, onFail) => ({
  type: GET_CUSTOMERS,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateCustomerPayment = (data, onSuccess, onFail) => ({
  type: UPDATE_CUSTOMER_PAYMENT,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getInvoices = (data, onSuccess, onFail) => ({
  type: GET_INVOICES,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const getStripeCustomer = (data, onSuccess, onFail) => ({
  type: GET_STRIPE_CUSTOMER,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateGoogleGroups = (data, onSuccess, onFail) => ({
  type: UPDATE_GOOGLE_GROUPS,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const clearGoogleGroups = (data, onSuccess, onFail) => ({
  type: CLEAR_GOOGLE_GROUPS,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});