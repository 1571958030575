import React from 'react';
import { connect } from "react-redux";
import CustomerDetails from './CustomerDetails'
import { getSiteData, updateSite } from '../../actions/siteActions'
import { cancelSubscription } from '../../actions/accountActions'
import { arrayFromKeyedObject } from '../../utils/dataUtils';

import {
  Page,
  Toolbar,
  SplitterSide,
  SplitterContent,
  List,
  ListItem
} from 'react-onsenui';

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closeRight: false,
      selectedCustomerId: null,
      singlePageListMode: true,
      customerDetailsKey: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.navCount !== prevProps.navCount) {
      this.setState({
        singlePageListMode: true
      })
    }
  }

  handleSelectCustomer = (customer) => {
    const customerDetailsKey = this.state.customerDetailsKey + 1;
    this.setState({
      selectedCustomerId: customer._id,
      singlePageListMode: false,
      customerDetailsKey: customerDetailsKey
    })
  }

  resetFromNav = () => {
    this.setState({
      singlePageListMode: true
    })
  }

  renderCustomerRow = (row, idx) => {
    let selected = false;
    if (row._id === this.state.selectedCustomerId){
      selected = true;
    }

    return (
      <ListItem
        tappable={true}
        key={idx}
        className={selected ? "selected" : ""}
        modifier="longdivider"
        onClick={() => this.handleSelectCustomer(row)}
      >
        <div>
          {row && row.primary_contact ?
            <span>
              Name: {row.primary_contact.name}<br />
              Email: {row.primary_contact.email}
            </span>
            :
            <span>{row.id}</span>}
          <br />
          &nbsp;
        </div>

    </ListItem>
    )
  }

  renderCustomerList = (customers) => {
    return (
      <Page>
        <Toolbar>
          <div className="center title-bold">
            Customers
          </div>
        </Toolbar>
        <br /><br />
        <List
          dataSource={customers}
          renderRow={(row, idx) => (
            this.renderCustomerRow(row, idx)
          )}
          renderFooter={() => <div></div>}
        />
      </Page>
    )
  }

  renderCustomer = () => {
    if (this.state.selectedCustomerId === null) {
      return (
        <Page>
        </Page>
      )
    }
    return (
      <CustomerDetails
        key={this.state.customerDetailsKey}
        showToast={(msg) => this.props.showToast(msg)}
        closeRight={this.state.closeRight}
        selectedCustomerId={this.state.selectedCustomerId}
      />
    )
  }

  render() {

    let filtered_customers = this.props.customers;
    if (this.props.search){
      filtered_customers = filtered_customers.filter(x => {
        if (!x.primary_contact){
          return x;
        }
        // search by name
        if (x.primary_contact.name){
          if (x.primary_contact.name.toLowerCase().includes(this.props.search.toLowerCase())){
            return x;
          }
        }
        // search by email
        if (x.primary_contact.email){
          if (x.primary_contact.email.toLowerCase().includes(this.props.search.toLowerCase())){
            return x;
          }
        }
        return false;
      })
    }
    if (this.props.browser.size === "large") {
      return (
        <React.Fragment>
          <SplitterContent >
            {this.renderCustomerList(filtered_customers)}
          </SplitterContent>
          <SplitterSide
            side="right"
            width={600}
            collapse={false}
            isOpen={true}
          >
            <Page> {this.renderCustomer()} </Page>
          </SplitterSide>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <SplitterContent>
            {this.state.singlePageListMode ? this.renderCustomerList(filtered_customers) : this.renderCustomer()}
          </SplitterContent>
          <SplitterSide
            side="right"
            width={600}
            collapse={true}
            isOpen={false}
          >
            <Page></Page>
          </SplitterSide>
        </React.Fragment>
      )
    }
  }
}

const mapStateToProps = (state) => {
  const customers = arrayFromKeyedObject(state.data.customers);
  return {
    logged_in: state.data.logged_in,
    customers: customers
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSiteData: (subscription_id, onSuccess, onFail) => {
      dispatch(getSiteData(subscription_id, onSuccess, onFail))
    },
    updateSite: (subscription_id, data, onSuccess, onFail) => {
      dispatch(updateSite(subscription_id, data, onSuccess, onFail))
    },
    cancelSubscription: (subscription_id, onSuccess, onFail) => {
      dispatch(cancelSubscription(subscription_id, onSuccess, onFail))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Customer);