import { takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actions/actionTypes';
import accountSaga from './accountSaga';
import siteSaga from './siteSaga';
import customerSaga from './customerSaga';
import productSaga from './productSaga';

export default function* rootSaga() {

    yield takeLatest(actionTypes.GET_ACCOUNT_DATA, accountSaga.getAccountData);
    yield takeLatest(actionTypes.GET_CUSTOMERS, customerSaga.getCustomers);
    yield takeLatest(actionTypes.LOG_USER_IN, accountSaga.logUserIn);
    yield takeLatest(actionTypes.LOG_USER_OUT, accountSaga.logUserOut);
    yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION, accountSaga.cancelSubscription);
    yield takeLatest(actionTypes.UPDATE_MONGO_CUSTOMER, accountSaga.updateMongoCustomer);
    yield takeLatest(actionTypes.UPDATE_PAYMENT_METHOD, accountSaga.updatePaymentMethod);
    yield takeLatest(actionTypes.PASSWORD_RESET, accountSaga.passwordReset);
    yield takeLatest(actionTypes.REGISTER_CODE_IN_SERVER, accountSaga.registerGoogleAuthCode);

    yield takeLatest(actionTypes.GET_SITE_DATA, siteSaga.getSiteData);
    yield takeLatest(actionTypes.UPDATE_SITE, siteSaga.updateSite);
    yield takeLatest(actionTypes.UPDATE_CUSTOMER_PAYMENT, customerSaga.updateCustomerPayment);
    yield takeLatest(actionTypes.GET_INVOICES, customerSaga.getInvoices);
    yield takeLatest(actionTypes.UPDATE_GOOGLE_GROUPS, customerSaga.updateGoogleGroups);
    yield takeLatest(actionTypes.CLEAR_GOOGLE_GROUPS, customerSaga.clearGoogleGroups);

    yield takeLatest(actionTypes.GET_STRIPE_CUSTOMER, customerSaga.getStripeCustomer);

    // Product saga
    yield takeLatest(actionTypes.GET_PRESTO_GO, productSaga.getPrestoGo);
    yield takeLatest(actionTypes.SUBSCRIBE_PRESTO_GO, productSaga.subscribePrestoGo);
    yield takeLatest(actionTypes.VALIDATE_COUPON, productSaga.validateCoupon);
}