function GetFormattedDate(dateObj) {
    var dd = dateObj.getDate();
    var mm = dateObj.getMonth() + 1; //January is 0!
    
    var yyyy = dateObj.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    } 
    if (mm < 10) {
      mm = '0' + mm;
    } 
    return mm + '/' + dd + '/' + yyyy;

}

module.exports = {
    GetFormattedDate: GetFormattedDate
}