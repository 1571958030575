
export const arrayFromKeyedObject = (obj) => {
  
    let returnArray = [];
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            returnArray.push(obj[key]);
        }
    }
    return returnArray;
}