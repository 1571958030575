import React from 'react';
import { connect } from "react-redux";
import { logUserOut } from '../actions/accountActions'
import { getCustomers } from '../actions/customerActions';
import License from './license/License';
import Tenants from './tenants/Tenants';
import {
  Page,
  Toolbar,
  ToolbarButton,
  Icon,
  SearchInput,
  Splitter,
  SplitterSide,
  List,
  ListItem,
  Toast
} from 'react-onsenui';
import { arrayFromKeyedObject } from '../utils/dataUtils';
import AccountDetails from './account/AccountDetails';
import Customer from './customers/Customer';
import { withRouter } from 'react-router-dom';
import { AUTO_SEARCH_TIMEOUT } from '../constants';

const areaList = ['Customers', 'Account', 'Switch to Customer Dashboard', 'Log Out'] //'Tenants', 'Tenant Report', License, 
class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchSet: '',
      searchFocused: false,
      page: 'customers',
      selectedLicense: null,
      closeLeft: true, //TODO: based on screen size?
      closeRight: true,
      showRight: true,
      openRight: true,
      navCount: 0, //used to be aware of rerouting to same navigation option
      showToast: false,
      toast: ''
    }
    this._toasterTimeout = null;
  }

  componentDidMount() {
    this.props.getCustomers();
    document.addEventListener("keydown", this.handleKeyDown);
    this.hideToast(5)
  }

  componentWillUnmount() {
    clearTimeout(this._toasterTimeout)
    this._toasterTimeout = null;
    clearTimeout(this._autoSearchTimeout)
    this._autoSearchTimeout = null;
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === "Enter" && this.state.searchFocused === true) {
      this.doSearch()
    }
  }

  getInitialRouteStack() {
    return (
      [
        {
          path: '#'
        }
      ]);
  }

  handleAreaNavigation = (row) => {
    this.setState({
      closeLeft: true,
      navCount: this.state.navCount + 1,
      searchText: ''
    })
    switch (row) {
      case "Customers":
        this.setState({
          page: "customers"
        });
        break;
      case "Account":
        this.setState({
          page: 'account'
        })
        break;
      case "Log Out":
        this.props.logUserOut({});
        //console.log("logging out");
        break;
      case "License":
        this.setState({
          page: 'license'
        })
        break;
      case "Tenants":
        this.setState({
          page: 'tenants'
        })
        break;
      case "Switch to Customer Dashboard":
        this.props.history.push('/user_dashboard');
        break;
      default:
        break;
    }
  }

  renderAreaRow = (row, idx) => {
    const selected = row.toLowerCase() === this.state.page.toLowerCase();
    return (
      <ListItem key={idx} className={selected ? "selected" : ""} modifier="longdivider" onClick={() => this.handleAreaNavigation(row)}>
        {row}
      </ListItem>
    )
  }

  hideToast = (seconds) => {
    clearTimeout(this._toasterTimeout);
    this._toasterTimeout = null;
    this._toasterTimeout = setTimeout(() => {
      this.setState({
        showToast: false
      })
    }, seconds * 1000);
  }

  renderPageLeft = () => {
    return (
      <React.Fragment>
        <Toolbar>
          <div className="center title-bold">
            Menu
          </div>
        </Toolbar>
        <br /><br />
        <List
          dataSource={areaList}
          renderRow={(row, idx) => (
            this.renderAreaRow(row, idx)
          )}
        />
      </React.Fragment>
    )
  }

  showToast = (message) => {
    this.setState({
      showToast: true,
      toast: message
    })
    this.hideToast(3)
  }

  searchEnabled = () => {
    switch (this.state.page) {
      case "customers":
        return true;
      case "license":
        return true;
      case "tenants":
        return true
      default:
        return false
    }
  }

  renderContent = () => {

    switch (this.state.page) {
      case "customers":
        return (
          <Customer search={this.state.searchSet} navCount={this.state.navCount} browser={this.props.browser} showToast={this.showToast} />
        );
      case "license":
        return (
          <License navCount={this.state.navCount} browser={this.props.browser} showToast={this.showToast} />
        );
      case "tenants":
        return (
          <Tenants navCount={this.state.navCount} browser={this.props.browser} />
        );
      case "account":
        return (
          <AccountDetails navCount={this.state.navCount} browser={this.props.browser} showToast={this.showToast} />
        );
      default:
        return null
    }

  }

  openLeftSidebar = () => {
    this.setState({
      closeLeft: false
    })
  }

  closeLeftSidebar = () => {
    this.setState({
      closeLeft: true
    })
  }

  handleSearchInput = (event) => {
    this.setState({ searchText: event.target.value })
    clearTimeout(this._autoSearchTimeout)
    this._autoSearchTimeout = null;
    this._autoSearchTimeout = setTimeout(() => {
      this.doSearch()
    }, AUTO_SEARCH_TIMEOUT);
  }

  doSearch = () => {
    console.log("doing search", this.state.searchText)
    this.setState({
      searchSet: this.state.searchText
    })
  }

  getUrlVars = () => {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  render() {

    let modifier = 'material';
    if (this.inIframe()) {
      modifier = 'goorange';
    }

    return (
      <React.Fragment>
        <Toast key={this.state.toast} isOpen={this.state.showToast}>{this.state.toast}</Toast>
        <Splitter>
          <Page renderToolbar={() =>
            <Toolbar modifier={modifier}>
              <div className="left">
                <ToolbarButton className="primary hamburguer-button" modifier='material' onClick={() => this.setState({ closeLeft: false })}>
                  <Icon icon="md-menu" size={24} />
                </ToolbarButton>
              </div>
              <div className="center" style={{ flexGrow: 0 }}>
                <SearchInput
                  value={this.state.searchText}
                  onChange={(event) => this.handleSearchInput(event)}
                  modifier={modifier}
                  placeholder='Search'
                  disabled={!this.searchEnabled()}
                  onFocus={() => { this.setState({ searchFocused: true }) }}
                  onBlur={() => { this.setState({ searchFocused: false }) }}
                />
              </div>
            </Toolbar>}
          />
          <SplitterSide
            side="left"
            width={200}
            collapse={'(max-width:860px)'} //this.state.closeLeft ? 'collapse' : 'portrait'
            isOpen={!this.state.closeLeft}
            onClose={() => this.closeLeftSidebar()}
          >
            <Page>
              {this.renderPageLeft()}
            </Page>
          </SplitterSide>
          {this.renderContent()}
        </Splitter>
      </React.Fragment>
    )

  }

}

const mapStateToProps = (state) => {
  const subscriptions = arrayFromKeyedObject(state.data.subscriptions);
  return {
    logged_in: state.data.logged_in,
    subscriptions: subscriptions,
    tenants: state.data.tenants,
    customers: state.data.customers
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getCustomers: () => {
      dispatch(getCustomers())
    },
    logUserOut: (data, onSuccess, onFail) => {
      dispatch(logUserOut(data, onSuccess, onFail))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
