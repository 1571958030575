import {
    GET_ACCOUNT_DATA,
    LOG_USER_IN,
    LOG_USER_OUT,
    CANCEL_SUBSCRIPTION,
    UPDATE_MONGO_CUSTOMER,
    UPDATE_PAYMENT_METHOD,
    PASSWORD_RESET,
    REGISTER_CODE_IN_SERVER
} from './actionTypes';
  
export const getAccountData = (data, onSuccess, onFail) => ({
    type: GET_ACCOUNT_DATA,
    payload: {
      data
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const logUserIn = (data, onSuccess, onFail) => ({
    type: LOG_USER_IN,
    payload: {
      data
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const logUserOut = (data, onSuccess, onFail) => ({
    type: LOG_USER_OUT,
    payload: {
      data
    },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});


export const cancelSubscription = (subscription_id, onSuccess, onFail) => ({
  type: CANCEL_SUBSCRIPTION,
  payload: {
    subscription_id
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updateMongoCustomer = (self, data, onSuccess, onFail) => ({
  type: UPDATE_MONGO_CUSTOMER,
  self,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const passwordReset = (onSuccess, onFail) => ({
  type: PASSWORD_RESET,
  payload: {},
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const updatePaymentMethod = (data, onSuccess, onFail) => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: {
    data
  },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});

export const registerCodeInServer = (google_auth_code, subscription_id, onSuccess, onFail) => ({
  type: REGISTER_CODE_IN_SERVER,
  payload: { google_auth_code, subscription_id },
  onSuccess: onSuccess || null,
  onFail: onFail || null
});