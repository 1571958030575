import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { registerCodeInServer } from '../actions/accountActions';

class OAuthCallback extends React.Component {

  constructor(props) {
    super(props);
    console.log("Search string", props.location.search);
    console.log("props ", props);
    const urlParams = new URLSearchParams(props.location.search);
    const subscription_id = localStorage.getItem("subscription_id");
    console.log("Gotten subscription ID ", subscription_id);
    this.state = {
      code: urlParams.get('code'),
      subscription_id
    }
  }

  onRegisterSuccess = () => {
    alert('Your google account was successfully connected');
    this.props.history.push('/autologin');
  }
  
  onRegisterFail = () => {
    alert('Your google account could not be connected');
    this.props.history.push('/autologin');

  }

  componentDidMount() {
    if (this.state.code && this.state.subscription_id) {
      this.props.registerCodeInServer(this.state.code, this.state.subscription_id, this.onRegisterSuccess, this.onRegisterFail);
    } else {
      this.onRegisterFail();
    }
  }

  render() {
    return (
      <p>Loading...</p>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  registerCodeInServer: (google_auth_code, subscription_id, onSuccess, onFail) => {
    dispatch(registerCodeInServer(google_auth_code, subscription_id, onSuccess, onFail))
  }
})

export default withRouter(connect(null, mapDispatchToProps)(OAuthCallback));