import React from 'react';
import {
    Button
} from 'react-onsenui';

class Home extends React.Component {


    render() {

        return (
            <div>
                <img style={{ position: 'absolute', width: '100vw', height: '100vh' }} src="/background_brick.jpg" />
                <div style={{ position: 'absolute', width: '100vw', height: '100vh', textAlign: 'center' }}>
                    <br /><br />
                    <img alt={'header_logo'} style={{ maxWidth: '50vw' }} src="/presto-header-logo_.png" />
                    <br /><br /><br /><br />
                    <Button className="btn-primary" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/autologin') }} >
                        Login
                </Button>
                </div>
     

            </div>

        )
    }
}

export default Home