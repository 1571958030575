import { GET_PRESTO_GO, SUBSCRIBE_PRESTO_GO, VALIDATE_COUPON } from "./actionTypes"

export const getPrestoGo = (data, onSuccess, onFail) => ({
    type: GET_PRESTO_GO,
    payload: { data },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const subscribePrestoGo = (email, first_name, last_name, company, token, price_id, update_default, coupon_id, onSuccess, onFail) => ({
    type: SUBSCRIBE_PRESTO_GO,
    payload: { email, first_name, last_name, company, token, price_id, coupon_id, update_default },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});

export const checkValidCoupon = (coupon, onSuccess, onFail) => ({
    type: VALIDATE_COUPON,
    payload: { coupon },
    onSuccess: onSuccess || null,
    onFail: onFail || null
});