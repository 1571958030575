import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { getAccountData, logUserIn } from '../actions/accountActions';
import {
  Button
} from 'react-onsenui';
const axios = require('axios')
const domain = 'https://dev-ugoafngs.auth0.com'
const audience = 'https://www.collobosapi.com/'
const client_id = '6W9WKIP1K4w0U2jJ89F0OjC8wiXzT3Kc'
const verifier = "gleuMemIP6kWLIjQM7PJmJuNi6IPS4ayebg_YAMioww"
const challenge = "BDmoZZH65olXCgz2Zu1VQdrIa6BYf2_giFxHXIwZt7U"

// let crypto;
// try {
//   crypto = require('crypto');
// } catch (err) {
//   console.log('crypto support is disabled!');
// }
// function base64URLEncode(str) {
//   return str.toString('base64')
//     .replace(/\+/g, '-')
//     .replace(/\//g, '_')
//     .replace(/=/g, '');
// }
//const verifier = base64URLEncode(crypto.randomBytes(32));
//const verifier = "gleuMemIP6kWLIjQM7PJmJuNi6IPS4ayebg_YAMioww"

axios.interceptors.response.use(response => {
  return response;
}, error => {
  console.log("AXIOS Error", error);
  return error.response;
});

// function sha256(buffer) {
//   return crypto.createHash('sha256').update(buffer).digest();
// }
// const challenge = base64URLEncode(sha256(verifier));

class Login extends Component {
  
  constructor(props) {
    super(props);
    let redirect_uri = process.env.REACT_APP_LOGIN_URL
    switch(props.redirect){
      case "prestogo_buy":
        redirect_uri=process.env.REACT_APP_URL + '/prestogo/buy'
        break;
      default:
        break;
    }
    console.log("Redirect uri is ", redirect_uri);
    this.state = {
      redirect_uri: redirect_uri
    }
  }

  authCheck = async (redirect_uri, onSuccess) => {
    try {
      const full_href = window.location.href;
      let simplify_url = true;
      if (full_href.includes("prestogo")){
        simplify_url = false;
      }

      var urlParams = new URLSearchParams(window.location.search);
      let code = urlParams.get('code')

      if (code !== undefined && code !== null) {

        const config = {
          headers: {
            'Content-Type': 'application/json',
          }
        }

        const data = {
          "grant_type": "authorization_code",
          "client_id": client_id,
          "code_verifier": verifier,
          "code": code,
          "redirect_uri": redirect_uri,
        }

        const URL = domain + '/oauth/token'
        const result = await axios.post(URL, data, config);

        if (simplify_url){
          window.history.replaceState({}, document.title, "/");
        }

        if (result && result.data && result.data.access_token){
          //logged in
          localStorage.setItem('accessToken', result.data.access_token);
          onSuccess();
        }
      }
      else {
        //console.log("no code, skipping token call")
      }
    }
    catch (error) {

    }
  }

  handleLoginUser = (redirect) =>{
    this.props.logUserIn();
  }

  componentDidMount() {
    //console.log(process.env.REACT_APP_LOGIN_URL);
    this.authCheck(this.state.redirect_uri, this.handleLoginUser);
    if (this.props.autoLogin === true){
      window.location = this.getHref();
    }
  }

  getHref = () => {
    return `${domain}/authorize?scope=openid profile&audience=${audience}&response_type=code&client_id=${client_id}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${this.state.redirect_uri}`
  }

  render() {

    let signin_href = this.getHref();

    if (this.props.autoLogin === true) {
      return (
        <div>
        </div>
      )
    }

    //TODO abstract to prestogo login component
    if (this.props.prestoGO === true) {

      const circleStyle = {
        height: 50,
        width: 50,
        backgroundColor: '#2B73BC',
        borderRadius: '50%',
        display: 'inline-block',
        textAlign: 'center'
      }
  
      const wrapperStyles = {
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
      }
  
      const numberStyle = {
        display: 'inline-block',
        paddingTop: 16,
        color: 'white'
      }

      return (
        <div style={wrapperStyles}>
          <div style={{
            height: '100%',
            width: "100%",
            display: "flex",
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
          }}>



            <div style={{ fontSize: 24, marginBottom: 24 }}>
              <img style={{ width: 125, height: 125 }} src="/prestogo_circle.png"></img>
            </div>
            <div style={{ fontSize: 24, marginBottom: 24 }}>
              <span style={{ marginLeft: 16 }}>The steps to complete your purchase are:</span>
            </div>
            <div style={{ marginLeft: "auto", marginRight: 'auto', fontSize: 24, marginBottom: 24, textAlign: 'left', width: 500, textOverflow: 'visible', whiteSpace: 'nowrap' }}>
              <span style={circleStyle}><span style={numberStyle}>1</span></span>
              <span style={{ marginLeft: 16 }}>Create Account / Login.</span>
              <br /><br />
              <span style={circleStyle}><span style={numberStyle}>2</span></span>
              <span style={{ marginLeft: 16 }}>Purchase with CC.</span>
              <br /><br />
              <span style={circleStyle}><span style={numberStyle}>3</span></span>
              <span style={{ marginLeft: 16, lineHeight: 2 }}>Copy the Subscription ID provided <br />after purchase and add it to the software.</span>
            </div>

            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 200 }}>
              <a style={{ width: 180, height: 100 }} href={signin_href}>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button style={{ fontSize: 24, cursor: 'pointer', backgroundColor: '#2B73BC' }} className="btn-primary full-width">
                    Start
                </Button>
                </div>
              </a>
            </div>

          </div>
        </div>
      )
    }

    return (
      <div className="App">
        <header className="App-header">
          <br /><br />
          <a href={signin_href}>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Button className="btn-primary">
                Login
            </Button>
          </div>
          </a>
        </header>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAccountData: (data, onSuccess, onFail) => {
      dispatch(getAccountData(data, onSuccess, onFail))
    },
    logUserIn: (data, onSuccess, onFail) => {
      dispatch(logUserIn(data, onSuccess, onFail))
    }
  }
}

export default connect(null, mapDispatchToProps)(withRouter(Login));

