import React from 'react';
import Login from './Login';
import { connect } from "react-redux";
import Dashboard from './Dashboard';
import Admin from './Admin';
import Session from './Session';
import PrestoGoBuy from './PrestoGoBuy';
import pkg from '../../package.json'
// import logo from './logo.svg';

import Home from './Home';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import OAuthCallback from './OAuthCallback';

class App extends React.Component {
  state = {
    browser: {width: 0, height: 0}
  }

  getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    let size = "small"
    if (width>600){
      size = "medium"
    }
    if (width>1213){
      size = "large"
    }
    this.setState({
      browser: {
        width,
        height,
        size
      }
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.getWindowDimensions();
    const script = document.createElement("script");
		script.src = "https://js.stripe.com/v3/";
		script.async = false;
    document.body.appendChild(script);
    console.log("Presto Accounts Version: " + pkg.version);
  }

  componentWillMount() {
    window.removeEventListener('resize', this.handleResize); 
  }

  handleResize = () => {
    this.getWindowDimensions();
  }

  getInitialRouteStack() {
    return (
      [
        {
          path: '#'
        }
      ]);
  }

  renderPage = (route, navigator) => {
    return (<div></div>)
  }

  render() {
    const { logged_in } = this.props;
    return(
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/login" exact render={() => <Login redirect="dashboard" />} />
          <Route path="/session" exact render={() => logged_in === true ? <Session browser={this.state.browser} /> : <Login redirect="dashboard" />} />
          <Route path="/user_dashboard" exact render={() => logged_in === true ? <Dashboard browser={this.state.browser} /> : <Login redirect="dashboard" />} />
          <Route path="/admin_dashboard" exact render={() => logged_in === true ? <Admin browser={this.state.browser} /> : <Login redirect="dashboard" />} />
          <Route path="/autologin" exact render={() => <Login autoLogin={true} redirect="dashboard"/>} />
          <Route path="/prestogo/buy" exact render={() => logged_in === true ? <PrestoGoBuy logged_in={logged_in} /> : <Login prestoGO={true} redirect="prestogo_buy" />} />
          <Route path="/oauth_callback" exact>
            <OAuthCallback />
          </Route>
        </Switch>
      </Router>
    )
  }

}


const mapStateToProps = (state) => {
  console.log("mapping logged in?", state.data.logged_in)
  return {
    logged_in: state.data.logged_in,
    customer: state.data.mongoCustomer
  };
};

export default connect(mapStateToProps, null)(App);

