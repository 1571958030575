import React from 'react';
import { connect } from "react-redux";
import { logUserOut } from '../../actions/accountActions'
import TenantDetails from './TenantDetails'

import {
    Page,
    Toolbar,
    ToolbarButton,
    Icon,
    Input,
    SplitterSide,
    SplitterContent,
    List,
    ListItem,
    Dialog,
    Button
} from 'react-onsenui';

class Tenants extends React.Component {
    state = {
        closeRight: false,
        selectedTenant: null,
        addModalOpen: false,
        addTenantApiKey: '',
        singlePageListMode: true
    }

    componentDidUpdate(prevProps){
        if (this.props.navCount !== prevProps.navCount){
          this.setState({
            singlePageListMode: true
          })
        }
      }

    handleSelectTenant = (tenant) => {
        console.log("selecting tenant", tenant);
        this.setState({
            selectedTenant: tenant
        })
    }

    resetFromNav = () => {
        this.setState({
          singlePageListMode: true
        })
      }

    renderTenantRow = (row, idx) => {
        console.log("row", idx, row)
        return (
            <ListItem tappable={true} key={idx} modifier="longdivider" onClick={() => this.handleSelectTenant(row)}>
                {row.name}<br />
                Status: Warning: Errors:
            </ListItem>
        );
    }

    renderTenantsList = () => {
        return (
            <Page>
                <Toolbar>
                    <div className="center title-bold">
                        Tenants
                    </div>
                    <div className="right">
                        <ToolbarButton onClick={() => { this.setState({ addModalOpen: true }) }}>
                            <Icon icon="md-plus" size={24} />
                        </ToolbarButton>
                        <ToolbarButton>
                            <Icon icon="md-minus" size={24} />
                        </ToolbarButton>
                    </div>
                </Toolbar>
                <br /><br />
                <List
                    dataSource={this.props.tenants}
                    renderRow={(row, idx) => (
                        this.renderTenantRow(row, idx)
                    )}
                />
            </Page>
        );
    }

    renderTenant = () => {
        if (this.state.selectedTenant === null) {
            return (
                <Page>
                </Page>
            );
        }

        return (
            <TenantDetails 
                closeRight={this.state.closeRight} 
                selectedTenant={this.state.selectedTenant} 
            />
        );
    }

    render() {
        let content;
        if (this.props.browser.size === "large") {
            content = (
                <React.Fragment>
                    <SplitterContent >
                    {this.renderTenantsList()}
                    </SplitterContent>
                    <SplitterSide
                    side="right"
                    width={600}
                    collapse={false}
                    isOpen={true}
                    >
                    <Page> {this.renderTenant()} </Page>
                    </SplitterSide>
                </React.Fragment>
            )
        } else {
            content = (
                <React.Fragment>
                  <SplitterContent>
                    {this.state.singlePageListMode ? this.renderTenantsList() : this.renderTenant()}
                  </SplitterContent>
                  <SplitterSide
                    side="right"
                    width={600}
                    collapse={true}
                    isOpen={false}
                  >
                    <Page></Page>
                  </SplitterSide>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <Dialog onCancel={() => { this.setState({ addModalOpen: false }) }}
                    isOpen={this.state.addModalOpen}
                    style={{ height: '100vh' }}
                    animation={'none'}
                    cancelable>
                    <div style={{ minWidth: '60vw', height: 200, textAlign: 'center' }}>
                        <Page>
                            <br /><br />
                            Enter your tenant API Key to Activate a new Tenant:
                            <br /><br />
                            <Input
                                value={this.state.addTenantApiKey} float
                                onChange={(event) => { this.setState({ addTenantApiKey: event.target.value }) }}
                                modifier='material'
                                placeholder='API Key' />
                            <br /><br />
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Button className="btn-primary">
                                    Submit
                                </Button>
                            </div>


                        </Page>
                    </div>
                </Dialog>
                {content}
            </React.Fragment>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        logged_in: state.data.logged_in,
        subscriptions: state.data.subscriptions,
        tenants: state.data.tenants
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logUserOut: (data, onSuccess, onFail) => {
            dispatch(logUserOut(data, onSuccess, onFail))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tenants);
