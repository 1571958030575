
import { call, put } from "redux-saga/effects";
import _ from "lodash";
import { normalize, schema } from 'normalizr';
const axios = require('axios')
const siteSchema = new schema.Entity('sites', {}, {idAttribute: '_id'});

function setAxiosAccessToken(){
  const accessToken = localStorage.getItem('accessToken');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
}

async function getSiteDataAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/getSiteData', payload);
    return response;
  }
  catch (e) {
    return e;
  }
}

async function updateSiteAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/updateSite', payload);
    return response;
  }
  catch (e) {
    return e;
  }
}

const siteSaga = {
  getSiteData: function* (action) {
    let success = false;
    try {
      const response = yield call(getSiteDataAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      const sites = [];
      const siteData = _.get(response, 'data.site', null)
      if (siteData){
        sites.push(siteData);
      }
      const normalized = normalize(sites, [siteSchema])
      yield put({ 
        type: "GET_SITE_DATA_SUCCESS",
        normalized: normalized
      });    
      if (action.onSuccess){
        action.onSuccess();
      }
    } catch (e) {
      success = false;
    }

    if (!success) {
      yield put({ type: "GET_SITE_DATA_FAIL", payload: {} });
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  updateSite: function* (action) {
    let success = false;
    try {
      const response = yield call(updateSiteAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      const sites = [];
      const siteData = _.get(response, 'data.site', null)
      if (siteData){
        sites.push(siteData);
      }
      const normalized = normalize(sites, [siteSchema])
      yield put({ 
        type: "UPDATE_SITE_DATA_SUCCESS",
        normalized: normalized
      });    
      if (action.onSuccess){
        action.onSuccess();
      }
    } catch (e) {
      success = false;
    }

    if (!success) {
      yield put({ type: "UPDATE_SITE_DATA_FAIL", payload: {} });
      if (action.onFail) {
        action.onFail();
      }
    }
  },
}

export default siteSaga;