import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
// import logo from './logo.svg';
import {
    ListItem,
    Button
} from 'react-onsenui';

class UpdateStripe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleSubmit = () => {
        // You can also use createToken to create tokens.
        // See our tokens documentation for more:
        // https://stripe.com/docs/stripe-js/reference#stripe-create-token
        this.props.stripe.createToken({ type: 'card', name: 'Name' }).then(({ token, err }) => {
            if (err){
                console.log("token err", err);
            }
            if (token){
                console.log("token at bottom", token);
                this.props.updatePaymentMethod(token);
            }     
        });

    };


    render() {
        return (
            <ListItem modifier="longdivider">
                <div className="left">
                    <div className="checkout">
                        <p>Please enter your payment information</p>
                        <CardElement />
                        <br />
                        <Button onClick={() => this.handleSubmit()} className="btn-primary">
                            Submit
                    </Button>
                    </div>

                </div>
                <div className="right">
                    <Button onClick={() => { this.props.cancelStripeUpdate() }} modifier="outline">
                        Cancel
                    </Button>
                </div>
            </ListItem>


        )
    }

}


export default injectStripe(UpdateStripe);
