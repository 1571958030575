export const GET_ACCOUNT_DATA = 'GET_ACCOUNT_DATA'
export const LOG_USER_IN = 'LOG_USER_IN'
export const LOG_USER_OUT = 'LOG_USER_OUT'
export const GET_SITE_DATA = 'GET_SITE_DATA'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD'
export const UPDATE_SITE = 'UPDATE_SITE'
export const UPDATE_MONGO_CUSTOMER = 'UPDATE_MONGO_CUSTOMER'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const UPDATE_CUSTOMER_PAYMENT = 'UPDATE_CUSTOMER_PAYMENT';
export const GET_INVOICES = 'GET_INVOICES';
export const GET_STRIPE_CUSTOMER = 'GET_STRIPE_CUSTOMER';
export const UPDATE_GOOGLE_GROUPS = 'UPDATE_GOOGLE_GROUPS';
export const GET_PRESTO_GO = "GET_PRESTO_GO";
export const SUBSCRIBE_PRESTO_GO = "SUBSCRIBE_PRESTO_GO";
export const VALIDATE_COUPON = "VALIDATE_COUPON";
export const CLEAR_GOOGLE_GROUPS = 'CLEAR_GOOGLE_GROUPS';
export const REGISTER_CODE_IN_SERVER = 'REGISTER_CODE_IN_SERVER';
