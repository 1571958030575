// import {createLogger} from "redux-logger";
import {createStore, applyMiddleware, compose} from "redux";
import createSagaMiddleware from "redux-saga";

import appReducer from "../reducers";
// import {fetchData} from "../actions/user-actions";
import rootSaga from '../sagas/rootSaga';
import DevTools from '../components/utils/DevTools';

// const logger = createLogger({
//   collapsed: true,
//   duration: true
// });

const sagaMiddleware = createSagaMiddleware()
const enhancer = compose(
  applyMiddleware(sagaMiddleware),
  DevTools.instrument()
);

const store = createStore(
  appReducer,
  enhancer
);
const rootSagaTask = sagaMiddleware.run(rootSaga);
rootSagaTask.error(error => {
    console.log("Saga error Catched", error);
})
// rootSagaTask.done.catch(error => {
//   console.log("Saga error Catched", error);
//   // Error here is a fatal error.
//   // None of the sagas down the road caught it.
// });
// sagaMiddleware.run(fetchData);

export default store;