import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import UpdateStripe from './UpdateStripe';

class StripeWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
                <Elements>
                    <UpdateStripe updatePaymentMethod={this.props.updatePaymentMethod} cancelStripeUpdate={this.props.cancelStripeUpdate} />
                </Elements>
            </StripeProvider>
        )
    }

}


export default StripeWrapper;
