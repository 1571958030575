/* global gapi */
import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash'
import { GetFormattedDate } from '../../utils/dateUtils'
import { arrayFromKeyedObject } from '../../utils/dataUtils'
import { clearGoogleGroups } from '../../actions/customerActions';

import {
    Page,
    Toolbar,
    ToolbarButton,
    Icon,
    List,
    ListItem,
    Dialog,
    Button,
    Input,
    Toast
} from 'react-onsenui';

// const axios = require('axios')
// var GoogleAuth; // Google Auth object.
// const discoveryUrl = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
// const SCOPE = 'https://www.googleapis.com/auth/admin.directory.group.readonly';
// import logo from './logo.svg';


// axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     console.log("AXIOS Error", error);
//     return error.response;
// });

class LicenseDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editModalOpen: false,
            confirmCancelModalOpen: false,
            confirmClearGoogleOpen: false,
            siteName: '',
            isToastOpen: false
        }
    }

    componentDidMount() {
        console.log("component loaded google group handler?");
        // this.handleClientLoad();
    }

    openEditModal = () => {
        this.setState({ 
            editModalOpen: true,
            confirmCancelModalOpen: false,
            confirmClearGoogleOpen: false
        })  
    }

    closeAllModals = () => {
        this.setState({ 
            editModalOpen: false,
            confirmCancelModalOpen: false,
            confirmClearGoogleOpen: false
        })
    }

    openConfirmCancelModal = () => {
        this.setState({ 
            editModalOpen: false,
            confirmCancelModalOpen: true,
            confirmClearGoogleOpen: false
        })  
    }

    openClearGoogleModal = () => {
        this.setState({ 
            editModalOpen: false,
            confirmCancelModalOpen: false,
            confirmClearGoogleOpen: true
        }) 
    }

    setupEditForm = () => {    
        this.setState({
            siteName: this.props.site.name || null
        })
    }

    submitEditForm = () => {
        this.closeAllModals();
        this.props.updateSite(this.props.selectedLicense.id, {name: this.state.siteName})
    }

    cancelSubscription = () => {
        this.closeAllModals();
        this.props.cancelSubscription(this.props.selectedLicense.id)
    }

    copyToClipboardLicense = () => {
        navigator.clipboard.writeText(this.props.selectedLicense.id).then(() => {
            this.setState({
                isToastOpen: true
            });
            setTimeout(() => {
                this.setState({
                    isToastOpen: false
                })
            }, 1000);
        }, ()=> {
            console.log("Clipboard paste failed");
        })
    }

    handleClearGoogleGroups = () => {
        const data = {
            subscription_id: this.props.selectedLicense.id
        }
        this.props.clearGoogleGroups(data)
    }

    render() {
        const last_refresh_date = new Date(this.props.site.last_refresh);
        if (this.props.selectedLicense === null || this.props.selectedLicense === undefined) {
            return (
                <Page></Page>
            )
        }
        const rowStyle = {
            height: 62
        }

        const renewal_date = new Date(this.props.selectedLicense.current_period_end * 1000)
        if (this.props.selectedLicense !== null) {
            return (
                <React.Fragment>
                    <Toast 
                        isOpen={this.state.isToastOpen}>
                        <p>Copied!</p>
                    </Toast>
                    <Dialog onCancel={() => { this.closeAllModals() }}
                        isOpen={this.state.confirmClearGoogleOpen}
                        style={{ height: '100vh' }}
                        animation={'none'}
                        cancelable>
                        <div style={{ minWidth: '60vw', height: 400 }}>
                            <Page>
                                <div style={{ textAlign: 'left' }}>
                                <ToolbarButton>
                                    <Icon onClick={() => {this.closeAllModals()}} icon="md-close" size={24} />
                                </ToolbarButton>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                          
                                    <div style={{marginTop: 32, marginBottom: 32}}>
                                        Clear synced google data for {this.props.selectedLicense.id} Subscription? <br /><br />
                                    </div>
                                    <div style={{marginTop: 0, marginBottom: 16, width: '100%', textAlign: 'center'}}>
                                    <Button onClick={() => this.closeAllModals()} modifier="btn-primary">
                                        No, Don't Cancel
                                    </Button>&nbsp;
                                    <Button onClick={() => {
                                        this.handleClearGoogleGroups()
                                        this.closeAllModals()
                                        }} modifier="outline">
                                        Yes
                                    </Button>
                                    </div>
                                    <br /><br />
                                </div>
                            </Page>
                        </div>
                    </Dialog>
                    <Dialog onCancel={() => { this.closeAllModals() }}
                        onPreShow={this.setupEditForm}
                        isOpen={this.state.editModalOpen}
                        style={{ height: '100vh' }}
                        animation={'none'}
                        cancelable>
                        <div style={{ minWidth: '60vw', height: 400 }}>
                            <Page>
                                <div style={{ textAlign: 'left' }}>
                                <ToolbarButton>
                                    <Icon style={{color: '#45535b'}} onClick={() => {this.closeAllModals()}} icon="md-close" size={24} />
                                </ToolbarButton>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                          
                                    <div style={{marginTop: 32, marginBottom: 32}}>
                                        Edit {this.props.selectedLicense.id} Subscription
                                    </div>
                                    <div style={{marginTop: 0, marginBottom: 16}}>
                                    <Input
                                        value={this.state.siteName} float
                                        onChange={(event) => { this.setState({ siteName: event.target.value }) }}
                                        modifier='material'
                                        placeholder='Site Name' />
                                    </div>
                                    <div style={{marginTop: 0, marginBottom: 16, width: '100%', textAlign: 'center'}}>
                                    <Button onClick={() => this.submitEditForm()} className="btn-primary">
                                        Submit
                                    </Button>
                                    </div>
                                    <div style={{width: '90%', display: 'inline-block', borderBottom: '1px solid grey', marginBottom: 32}}></div>
                                    <br /><br />
                                </div>
                            </Page>
                        </div>
                    </Dialog>
                    <Dialog onCancel={() => { this.closeAllModals() }}
                        isOpen={this.state.confirmCancelModalOpen}
                        style={{ height: '100vh' }}
                        animation={'none'}
                        cancelable>
                        <div style={{ minWidth: '60vw', height: 400 }}>
                            <Page>
                                <div style={{ textAlign: 'left' }}>
                                <ToolbarButton>
                                    <Icon onClick={() => {this.closeAllModals()}} icon="md-close" size={24} />
                                </ToolbarButton>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                          
                                    <div style={{marginTop: 32, marginBottom: 32}}>
                                        Cancel {this.props.selectedLicense.id} Subscription? <br /><br />
                                        This operation cannot be undone, you will have to subscribe again with your presto installation.
                                    </div>
                                    <div style={{marginTop: 0, marginBottom: 16, width: '100%', textAlign: 'center'}}>
                                    <Button onClick={() => this.closeAllModals()} modifier="btn-primary">
                                        No, Don't Cancel
                                    </Button>&nbsp;
                                    <Button onClick={() => this.cancelSubscription()} modifier="outline">
                                        Yes, Cancel
                                    </Button>
                                    </div>
                                    <br /><br />
                                </div>
                            </Page>
                        </div>
                    </Dialog>
                    <Page>
                        <Toolbar>
                            <div className="center">
                                {this.props.selectedLicense.id}
                            </div>
                            <div className="right">
                                <ToolbarButton>
                                    <Icon style={{color: "#45535b", marginRight: 8}} onClick={() => { this.setState({ editModalOpen: true }) }} icon="md-edit" size={24} />
                                    <Icon style={{color: "#45535b"}} icon="md-delete" onClick={this.openConfirmCancelModal} size={24} />
                                </ToolbarButton>
                            </div>
                        </Toolbar>
                        <br /><br />
                        <List>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Name</div>
                                <div style={rowStyle} className="right" >{this.props.site.name}</div>
                            </ListItem>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >License</div>
                                <div style={rowStyle} className="right" > 
                                    {this.props.selectedLicense.id}
                                    <Button
                                        className="btn-primary full-width"
                                        style={{
                                            marginLeft: 10
                                        }}
                                        onClick={this.copyToClipboardLicense}
                                        >
                                        Copy
                                    </Button>
                                </div>
                            </ListItem>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Status</div>
                                <div style={rowStyle} className="right" > {this.props.selectedLicense.status} </div>
                            </ListItem>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Renewal Date</div>
                                {
                                    this.props.selectedLicense.status === "canceled" ?
                                    <div style={rowStyle} className="right" >canceled</div>
                                    :
                                    <div style={rowStyle} className="right" > {GetFormattedDate(renewal_date)} </div>
                                }
                            </ListItem>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Users</div>
                                {
                                    this.props.selectedLicense.status === "canceled" ?
                                    <div style={rowStyle} className="right" >canceled</div>
                                    :
                                    <div style={rowStyle} className="right" > {_.get(this.props.selectedLicense, 'items.data[0].quantity', 0)} </div>
                                }
                            </ListItem>
                            <ListItem style={{display: 'flex'}} modifier="longdivider">
                                <div style={rowStyle} className="left" >Google Sync Status: {this.props.google_synced ? `Last Synced: ${last_refresh_date.toLocaleDateString()} ${last_refresh_date.toLocaleTimeString()}` : "Not Synced"}</div>
                                {this.props.google_synced ?
                                    <div style={rowStyle} className="right">
                                        <Button
                                            className="btn-primary full-width"
                                            style={{
                                                marginLeft: 10
                                            }}
                                            onClick={() => this.openClearGoogleModal()}
                                        >
                                            Desync Google
                                    </Button>
                                    </div> : <div style={rowStyle} className="right"></div>}
                            </ListItem>
                        </List>

                    </Page>
                </React.Fragment>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const sites = arrayFromKeyedObject(state.data.sites);
    let site = _.find(sites, {subscription_id: _.get(ownProps, "selectedLicenseId", null)});
    let google_synced = false;
    if (site && site.google_groups && site.google_groups.length){
        google_synced = true;
    }
    return {
      site: site || {},
      selectedLicense: state.data.subscriptions[ownProps.selectedLicenseId],
      google_synced: google_synced
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        clearGoogleGroups: (data, onSuccess, onFail) => {
            dispatch(clearGoogleGroups(data, onSuccess, onFail))
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LicenseDetail);
