import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Input, List, ListItem, Button, ProgressCircular, Toast } from 'react-onsenui';
import { getPrestoGo, subscribePrestoGo, checkValidCoupon } from '../actions/productActions';
import { getAccountData, updatePaymentMethod } from '../actions/accountActions';
import { connect } from 'react-redux';
import StripeWrapper from './account/StripeWrapper';
import _ from 'lodash';

class PrestoGoBuy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prestoInfoLoaded: false,
            key: new Date(),
            accountDataLoaded: false,
            email: '',
            first_name: '',
            coupon: '',
            couponValidated: false,
            processingModal: false,
            copiedToastOpen: false,
            successful_subscription_message: '',
            successful_subscription_id: '',
            canProceed: true
        };
    }

    componentDidMount(){
        this.props.getPrestoGo(this.onGetPrestoGoSuccess);
        this.props.getAccountData(null, this.onGetAccountDataSuccess);
    }

    onGetPrestoGoSuccess = () => {
        if (this.props.products[process.env.REACT_APP_PRESTO_GO_PRODUCT_ID].prices.length === 1) {
            this.setState({
                price_id: this.props.products[process.env.REACT_APP_PRESTO_GO_PRODUCT_ID].prices[0].id
            })
        }
        this.setState({
            prestoInfoLoaded: true
        });
    }

    onGetAccountDataSuccess = () => {
        this.setState({
            accountDataLoaded: true,
            email: this.props.mongoCustomer.primary_contact.email,
            first_name: this.props.mongoCustomer.primary_contact.name
        });
    }

    getPriceInput = (product) => {
        return (
            product.prices.map(price => { return `$${price.unit_amount/100} / ${price.recurring.interval}`})
        )
            
        
        // return (
        //     <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
        //         <span><b>Price: </b></span>

        //         <Select
        //             style={{ width: "100%", paddingLeft: 20 }}
        //             value={this.state.price_id}
        //             placeholder={"Product"}
        //             onChange={(event) => this.setState({price: event.target.value})}>
        //             {product.prices.map(price => {
        //                 return (<option key={price.id} value={price.id}>{`$${price.unit_amount/100} / ${price.recurring.interval}`}</option>)
        //             })}
        //         </Select>
        //     </div>
        // );
    }

    cancelStripeUpdate = () => {
        this.setState({
            stripeFormActive: false
        })
    }

    onSuccessSubscribe = (id) => {
        this.setState({ 
            processingModal: false,
            stripeUpdating: false, 
            successful_subscription_message: `Your license key is ${id}`,
            successful_subscription_id: id
        });
    }

    onFailSubscribe = (err) => {
        console.log("FULL ERROR");
        console.log(err);
        alert(_.get(err, 'message', 'Something went wrong with the payment, please check your information and try again.'));
        this.setState({
            processingModal: false,
            stripeUpdating: false
        });
    }

    updatePaymentMethod = (token) => {
        if (token && token.id){
          const handleDone = () => {this.setState({stripeUpdating: false, canProceed: true})}
          this.props.updatePaymentMethod(token, handleDone, handleDone);
        }
        this.setState({
          stripeFormActive: false,
          stripeUpdating: true,
          canProceed: false
        })
      }
    
    proceedWithDefault = () => {
        this.setState({
            processingModal: true
        })
        this.props.subscribePrestoGo(this.state.email, this.state.first_name, this.state.last_name, this.state.company, this.props.defaultPaymentMethod.id, this.state.price_id, false, this.state.coupon, this.onSuccessSubscribe, this.onFailSubscribe);
    }

    copyToClipboardLicense = () => {
        console.log("copying to clipboard")
        navigator.clipboard.writeText(this.state.successful_subscription_id).then(() => {
            this.setState({
                copiedToastOpen: true
            });
            setTimeout(() => {
                this.setState({
                    copiedToastOpen: false
                })
            }, 1000);
        }, ()=> {
            console.log("Clipboard paste failed");
        })
    }

    onValidateCouponSuccess = (success) => {
        if (success) {
            // validation is successful
            this.setState({
                couponValidated: true
            });
        } else {
            alert("Coupon is invalid");
            this.setState({
                coupon: ""
            })
        }
    }

    validateCoupon = () => {
        this.props.validateCoupon(this.state.coupon, this.onValidateCouponSuccess);
    }

    getContent = () => {
        if (!localStorage.accessToken) {
            return (<span>Not logged in </span>)
        }

        if (!this.state.prestoInfoLoaded || !this.state.accountDataLoaded) {
            return (<span>Loading...</span>)
        }

        const innerWidth = window.innerWidth;
        const wideScreen = innerWidth > 1200 ? true: false

        return (
            <div>
                {wideScreen ?<div style={{ display: "flex", flexDirection: "row"}}>
                    <div style={{ flex: "0 0 85%", paddingLeft: "7.5%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-start" }}>
                        {/* <img style={{ width: 200, height: 200, position: 'absolute', left: 0, marginLeft: "7.5%" }} src="/prestogo_circle.png"></img> */}
                        <img style={{ width: 300, position: 'absolute', left: -4, top: 48, marginLeft: "7.5%" }} src="/presto_go_icon.png"></img>
                        <img style={{width: 300, height: 300}} src="/guarantee.png"></img>
                    </div>
                </div> :
                <div style={{ textAlign: 'left'}}>
                    <img style={{ width: 300, maxWidth: '40vw', left: 0, marginLeft: "7.5%" }} src="/presto_go_icon.png"></img>
                    
                    <img style={{width: 200, maxWidth: '40vw'}} src="/guarantee.png"></img>
                </div>
    }
                { !this.state.successful_subscription_message ? <div style={{ display: "flex", flexDirection: "row", marginTop: 20}} className="left-container">
                    <div style={{ flex: "1 0 0", display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: "0 0 70%", paddingLeft: "15%"}}>
                            <Input
                                style={{ width: "100%" }}
                                value={this.state.first_name}
                                onChange={(event) => this.setState({ first_name: event.target.value })}
                                float
                                placeholder="First name"
                            />
                            <br /><br />
                            <Input
                                style={{ width: "100%" }}
                                value={this.state.last_name}
                                onChange={(event) => this.setState({ last_name: event.target.value })}
                                float
                                placeholder="Last name"
                            />
                            <br /><br />
                            <Input
                                style={{ width: "100%" }}
                                value={this.state.email}
                                onChange={(event) => this.setState({ email: event.target.value })}
                                float
                                placeholder="Email"
                                disabled={true}
                            />
                            <br /><br />
                            <Input
                                style={{ width: "100%" }}
                                value={this.state.company}
                                onChange={(event) => this.setState({ company: event.target.value })}
                                float
                                placeholder="Company"
                            />
                        </div>
                    </div>
                    <div style={{ flex: "1 0 0", display: "flex", flexDirection: "row" }} className="right-container">
                        <div style={{ flex: "0 0 70%", paddingLeft: "15%" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <span><b>Product: </b></span>
                                <span style={{ width: "100%", paddingLeft: 20 }}>{this.props.products[process.env.REACT_APP_PRESTO_GO_PRODUCT_ID].name}</span>
                            </div>
                            <br/><br/>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <span><b>Term: </b></span>
                                <span style={{ width: "100%", paddingLeft: 20 }}>Annual subscription</span>
                            </div>
                            <br /><br />
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <span><b>Price: </b></span>
                                <span style={{ width: "100%", paddingLeft: 20 }}>{this.getPriceInput(this.props.products[process.env.REACT_APP_PRESTO_GO_PRODUCT_ID])}</span>
                            </div>
                            {/* {
                                this.getPriceInput(this.props.products[process.env.REACT_APP_PRESTO_GO_PRODUCT_ID])
                            } */}
                            <br /><br />
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <span><b>Coupon: </b></span>
                                <span style={{ width: "100%", paddingLeft: 20, flex: "1", padding: "0 20px 0 20px" }}>
                                    <Input
                                        className="black-text"
                                        style={{ width: "100%" }}
                                        value={this.state.coupon}
                                        disabled={this.state.couponValidated}
                                        onChange={(event) => this.setState({ coupon: event.target.value })}
                                        placeholder="Coupon ID"/>
                                </span>
                                <Button
                                    onClick={() => this.validateCoupon()}
                                    disabled={this.state.couponValidated}>
                                    Validate
                                </Button>
                            </div>
                        </div>
                    </div>
                </div> : null }
                { !this.state.successful_subscription_message ? <div style={{ marginTop: 20, display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "0 0 85%", paddingLeft: "7.5%" }}>
                        <List>
                            {this.state.stripeFormActive ? (
                                <StripeWrapper
                                    updatePaymentMethod={this.updatePaymentMethod}
                                    cancelStripeUpdate={this.cancelStripeUpdate}
                                />
                            ) : null}
                            {!this.state.stripeUpdating && !this.state.stripeFormActive ?
                                <ListItem modifier="longdivider">
                                    <div className="left">
                                        Payment Method: CC -{" "}
                                        {_.get(this.props, "defaultPaymentMethod.last4", "None")}
                                    </div>
                                    <div className="right" style={{
                                        width: "15%",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end"
                                    }}>
                                        <Button
                                            onClick={() => this.setState({ stripeFormActive: true })}
                                            modifier={"outline"}>
                                            Change
                                        </Button>
                                    </div>
                                </ListItem>
                                :
                                null}
                            {this.state.stripeUpdating ?

                                <ListItem modifier="longdivider">
                                    <div className="left">
                                        <ProgressCircular indeterminate />
                                    </div>
                                    <div className="right">
                                        <Button disabled modifier="outline">
                                            Change
                                                </Button>
                                    </div>
                                </ListItem>
                                : null}
                        </List>
                    </div>
                </div> : null }
                { !this.state.successful_subscription_message ? <div style={{ marginTop: 20, display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingRight: "7.5%" }}>
                    <Button
                        disabled={!this.canProceedWithValidation()}
                        onClick={this.proceedWithDefault}
                        className="btn-primary">
                        Buy Now
                    </Button>
                </div> : null }
                {
                    this.state.successful_subscription_message ?
                        <div style={{ marginTop: 20, textAlign: "left", paddingLeft: "7.5%" }}>
                            Thank you for your purchase. <br />
                            <p>{this.state.successful_subscription_message}</p>
                            <Button
                                className="btn-primary btn-sm"
                                style={{
                                    marginLeft: 10
                                }}
                                onClick={this.copyToClipboardLicense}>
                                Copy
                            </Button>
                        </div> : null
                }
            </div>
        )
    }

    canProceedWithValidation(){
        if (!this.state.canProceed){
            return false;
        }
        if (this.state.successful_subscription_message !== ''){
            return false;
        }
        const hasDefaultPaymentMethod = _.get(this.props, 'defaultPaymentMethod.id', null)
        if (hasDefaultPaymentMethod == null){
            return false
        }

        if (this.state.coupon && !this.state.couponValidated) {
            return false
        }

        return true;
    }

    render() {

       const scrollable = {     
            height: '100%',
            overflowY: 'auto'
        }

        return (
            <div>
                <Toast
                    isOpen={this.state.copiedToastOpen}>
                    <p>Copied!</p>
                </Toast>
            <div
                className={"prestogo"}
                style={{ ...scrollable, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, background: "#2469B9", color: "white" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ flex: "0 1 0", width: "100%", textAlign: "center" }}>
                        { this.getContent() }
                    </div>
                </div>
            </div>
            {this.state.processingModal ? 
            <div style={{position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', backgroundColor: "rgba(0, 0, 0, 0.5)"}} >
                <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ flex: "0 1 0", width: "100%", textAlign: "center" }}>
                    <ProgressCircular indeterminate /><br />
                    Processing...
                    </div>
                </div>
                
            </div>
            :
            null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.data.products,
        mongoCustomer: state.data.mongoCustomer,
        logged_in: state.data.logged_in,
        defaultPaymentMethod: state.data.defaultPaymentMethod
    };
}

const mapDispatchToProps = dispatch => ({
    getPrestoGo: (onSuccess, onFail) => dispatch(getPrestoGo(null, onSuccess, onFail)),
    subscribePrestoGo: (email, first_name, last_name, company, source_token, price_id, updateDefault, coupon_id, onSuccess, onFail) => dispatch(subscribePrestoGo(email, first_name, last_name, company, source_token, price_id, updateDefault, coupon_id, onSuccess, onFail)),
    getAccountData: (data, onSuccess, onFail) => dispatch(getAccountData(data, onSuccess, onFail)),
    updatePaymentMethod: (data, onSuccess, onFail) => {
        dispatch(updatePaymentMethod(data, onSuccess, onFail))
    },
    validateCoupon: (coupon, onSuccess, onFail) => dispatch(checkValidCoupon(coupon, onSuccess, onFail))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrestoGoBuy));