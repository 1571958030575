
import { call, put } from "redux-saga/effects";
import _ from "lodash";
import { normalize, schema } from 'normalizr';
const axios = require('axios')
const customerSchema = new schema.Entity('customers', {}, { idAttribute: '_id' });
const subscriptionSchema = new schema.Entity('subscriptions');
const stripeCustomerSchema = new schema.Entity('stripe_customers');
const invoiceSchema = new schema.Entity('invoices');
const siteSchema = new schema.Entity('sites', {}, {idAttribute: '_id'});

function setAxiosAccessToken(){
  const accessToken = localStorage.getItem('accessToken');
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
}

function responseSuccess(response){
  if (response && response.status === 200 && response.statusText === "OK"){
    return true;
  }
  return false;
}

async function updateGoogleGroupsAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/updateGoogleGroups', payload);
    return response;
  }
  catch (e) {
    return e;
  }
}

async function clearGoogleGroupsAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/clearGoogleGroups', payload);
    return response;
  }
  catch (e) {
    return e;
  }
}

async function getCustomersAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.get(process.env.REACT_APP_SERVER_URL+'/api/customer/getCustomers', payload);
    return response;
  }
  catch (e) {
    return e;
  }
}

async function getStripeCustomerAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.get(process.env.REACT_APP_SERVER_URL+`/api/customer/getStripeCustomer?stripe_id=${payload.data.stripe_id}`, );
    return response;
  }
  catch (e) {
    return e;
  }
}

async function updateCustomerPaymentAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/updateCustomerPayment', payload);
    return response;
  }
  catch (e) {
    return e;
  }
}

async function getInvoicesAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.get(process.env.REACT_APP_SERVER_URL+`/api/customer/getInvoices?stripeCustomerId=${payload.data.stripeCustomerId}`, );
    return response;
  }
  catch (e) {
    return e;
  }
}

const customerSaga = {
  updateGoogleGroups: function*(action) {
    let success = false;
    try {
      const response = yield call(updateGoogleGroupsAPI, action.payload);
      success = responseSuccess(response);
      if (action.onSuccess){
        action.onSuccess()
      }
    } catch (e) {
      success = false
    }

    if (!success) {
      yield put({ type: "UPDATE_GOOGLE_GROUPS_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },

  clearGoogleGroups: function*(action) {
    let success = false;
    try {
      const response = yield call(clearGoogleGroupsAPI, action.payload);
      success = responseSuccess(response);
      if (success){
        const siteData = _.get(response, 'data.site', null)
        if (siteData){
          const normalized = normalize([siteData], [siteSchema])
          yield put({
            type: "CLEAR_GOOGLE_GROUPS_SUCCESS",
            normalized: normalized
          });
        }
        else{
          console.log("did not find siteData in the response object")
        }
      }
      if (action.onSuccess){
        action.onSuccess()
      }
    } catch (e) {
      success = false
    }

    if (!success) {
      yield put({ type: "CLEAR_GOOGLE_GROUPS_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },

  updateCustomerPayment: function*(action) {
    let success = false;
    try {
      const response = yield call(updateCustomerPaymentAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      const stripeCustomer = _.get(response, 'data.stripeCustomer', null);
      if (stripeCustomer) {
        yield put({
          type: "UPDATE_CUSTOMER_PAYMENT_SUCCESS",
          normalized: normalize(stripeCustomer, stripeCustomerSchema)
        });
      }
      if (action.onSuccess){
        action.onSuccess()
      }
    } catch (e) {
      success = false
    }

    if (!success) {
      yield put({ type: "UPDATE_CUSTOMER_PAYMENT_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },
  getCustomers: function*(action) {
    let success = false;
    try {
      const response = yield call(getCustomersAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      const customers = _.get(response, 'data.customers', null);
      yield put({ 
        type: "GET_CUSTOMER_DATA_SUCCESS",
        normalized: normalize(customers, [customerSchema])
      });
      if (action.onSuccess){
        action.onSuccess();
      }
    } catch (e) {
      success = false;
      console.log(e);
    }

    if (!success) {
      yield put({ type: "GET_CUSTOMER_DATA_FAIL", payload: {} });
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  getStripeCustomer: function*(action){
    let success = false;
    try {
      const response = yield call(getStripeCustomerAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      const stripeCustomer = _.get(response, 'data.stripe_customer.result', null);
      let subscriptions = []
      if (stripeCustomer) {
        const sSubscriptions = _.get(stripeCustomer, 'subscriptions.data', []);
        if (sSubscriptions.length) {
            subscriptions = subscriptions.concat(sSubscriptions);
            stripeCustomer.subscriptions.data = sSubscriptions.map(s => s.id);
        }
        yield put({
          type: "GET_STRIPE_CUSTOMER_SUCCESS",
          normalized: normalize(stripeCustomer, stripeCustomerSchema)
        })
        if (subscriptions.length) {
        yield put({
          type: "GET_SUBSCRIPTIONS_DATA_SUCCESS",
          normalized: normalize(subscriptions, [subscriptionSchema])
        });
      }
      }
      if (action.onSuccess){
        action.onSuccess();
      }
    } catch (e) {
      success = false;
      console.log(e);
    }

    if (!success) {
      yield put({ type: "GET_STRIPE_CUSTOMER_FAIL", payload: {} });
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  getInvoices: function*(action) {
    let success = false;
    try {
      const response = yield call(getInvoicesAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      const invoices = _.get(response, 'data.invoices.result.data', null);
      if (invoices) {
        yield put({
          type: "GET_INVOICES_SUCCESS",
          normalized: normalize(invoices, [invoiceSchema])
        })
      }
      if (action.onSuccess){
        action.onSuccess();
      }
    } catch (e) {
      success = false;
      console.log(e);
    }

    if (!success) {
      yield put({ type: "GET_INVOICES_FAIL", payload: {} });
      if (action.onFail) {
        action.onFail();
      }
    }
  },
}

export default customerSaga;