console.log("Environment ", process.env);

export const productsMapping = process.env.REACT_APP_ENV === "development" ? {
    "prod_HK00dCSIOeerPo": { "name": "PrestoGo", "isPresto3": false},
    "prod_EpHneoKK8ugPR5": { "name": "Professional On-boarding - 60 minutes", "isPresto3": false},
    "prod_EmZbbl88xzjW7p": { "name": "Presto 2.8", "isPresto3": false},
    "prod_EM3NYsVkWHKShs": { "name": "Presto 3", "isPresto3": true}
}: {
    "prod_H3iMZwN19VtYxn": { "name": "PrestoGo", "isPresto3": false},
    "prod_GIampx7MMpUwLq": { "name": "Presto 3", "isPresto3": true},
    "prod_EYhlpjEEvgOn8h": { "name": "Presto 360", "isPresto3": false},
    "prod_ETsOKueXimLb0X": { "name": "Professional On-boarding - 60 minutes", "isPresto3": false},
    "prod_EMJEHZDToKb3A0": { "name": "Presto 2.8", "isPresto3": false}
};