import { call, put } from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
const axios = require('axios');
const productsSchema = new schema.Entity('products');
function setAxiosAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
}

async function getPrestoGoInfoAPI() {
    setAxiosAccessToken()
    return axios.get(process.env.REACT_APP_SERVER_URL + '/api/products/presto_go');
}

async function subscribePrestoGoAPI(payload) {
    setAxiosAccessToken();
    return axios.post(process.env.REACT_APP_SERVER_URL + '/api/products/subscribe_presto_go', payload);
}

async function validateCouponAPI(payload) {
    setAxiosAccessToken();
    return axios.post(process.env.REACT_APP_SERVER_URL + '/api/products/validate_coupon', payload);
}

const productSaga = {
    getPrestoGo: function*(action) {
        try {
            const response = yield call(getPrestoGoInfoAPI, action.payload);
            if (response && response.status === 200 ) {
                yield put({
                    type: 'GET_PRESTO_GO_SUCCESS',
                    normalized: normalize(response.data.products, [productsSchema])
                });
                if (action.onSuccess) {
                    action.onSuccess();
                }
            } else {
                console.log("Undefined error");
                yield put({
                    type: "GET_PRESTO_GO_FAIL"
                });
                if (action.onFail) {
                    action.onFail();
                }
            }
        } catch(ex) {
            console.log("Exception ", ex);
            yield put({
                type: "GET_PRESTO_GO_FAIL"
            });
            if (action.onFail) {
                action.onFail();
            }
        }
    },

    subscribePrestoGo: function*(action) {
        try {
            const response = yield call(subscribePrestoGoAPI, action.payload);
            if (response && response.status === 200) {
                yield put({
                    type: 'SUBSCRIBE_PRESTO_GO_SUCCESS'
                });
                if (action.onSuccess) {
                    action.onSuccess(response.data.id);
                }
            } else {
                yield put({
                    type: "SUBSCRIBE_PRESTO_GO_FAIL"
                });
                if (action.onFail) {
                    action.onFail(response.data.err);
                }
            }
        } catch(ex) {
            yield put({
                type: "SUBSCRIBE_PRESTO_GO_FAIL"
            });
            if (action.onFail) {
                action.onFail();
            }
        }
    },
    
    validateCoupon: function*(action) {
        try {
            const response = yield call(validateCouponAPI, action.payload);
            if (response && response.status === 200) {
                yield put({
                    type: 'VALIDATE_COUPON_SUCCESS'
                })
                if (action.onSuccess) {
                    action.onSuccess(response.data.data.valid);
                }
            } else {
                yield put({
                    type: "VALIDATE_COUPON_FAILURE"
                });
                if (action.onFail) {
                    action.onFail();
                }
            }
        } catch(ex) {
            yield put({
                type: "VALIDATE_COUPON_FAILURE"
            });
            if (action.onFail) {
                action.onFail();
            }
        }
    }
}

export default productSaga;