import _ from 'lodash';

const initialStateSchema = {
  logged_in: false,
  subscriptions: {},
  defaultPaymentMethod: null,
  tenants: [],
  user: {},
  sites: {},
  stripe_customers: {},
  invoices: {},
  products: {}
};

export default (state = _.cloneDeep(initialStateSchema), action) => {
  let returnObj = {};

  if (action && (action.normalized || action.removeKeys || action.setKeys)) {
    returnObj = {
      ...state,
    }
    if (action.normalized) {
      Object.keys(action.normalized.entities).forEach(function (key) {
        returnObj[key] = {
          ...returnObj[key],
          ...action.normalized.entities[key]
        }
      });
    }

    if (action.removeKeys) {
      // the redux store entities object we are removing a key from
      for (let i = 0; i < action.removeKeys.length; i++) {
        returnObj[action.removeKeys[i].entities] = _.omit(returnObj[action.removeKeys[i].entities], action.removeKeys[i].ids);
      }

    }

    if (action.setKeys) {
      action.setKeys.forEach(keyVal => {
        //console.log("iterating", keyVal);
        returnObj[keyVal.key] = keyVal.value
      });
    }
    return returnObj;

  }

  if (action && action.overrides){
    returnObj = {
      ...state,
    }
    action.overrides.map(x => {
      returnObj[x.key] = x.value
      return null;
    })
    return returnObj;
  }
  // removes key(s) from a redux store entities object when passed removeKeys
  // from the saga we will pass in data with this format: {removeKeys: [{entities: 'folders', ids: [106]}]}
  
  
  switch (action.type) {
    // case "GET_SUBSCRIPTIONS_SUCCESS":
    //   console.log(JSON.stringify(action.subscriptions));
    //   return {
    //     ...state,
    //     subscriptions: action.subscriptions
    //   }
    case "LOG_USER_IN_SUCCESS":
      return {
        ...state,
        logged_in: true
      }
    case "LOG_USER_OUT_SUCCESS":
      //console.log("logging out success?")
      return _.cloneDeep(initialStateSchema)
    default:
      return state;
  }
}
