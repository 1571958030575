
import { call, put } from "redux-saga/effects"
import _ from "lodash"
import { normalize, schema } from 'normalizr'
const axios = require('axios')
const subscriptionSchema = new schema.Entity('subscriptions')
const sitesSchema = new schema.Entity('sites', {}, { idAttribute: "_id" });
const customerSchema = new schema.Entity('customers', {}, { idAttribute: "_id" });
function setAxiosAccessToken(){
  const accessToken = localStorage.getItem('accessToken')
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
}

async function getAccountDataAPI() {
  try {
    setAxiosAccessToken()
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/getAccountDataWeb', {
      _: {}
    })
    return response
  }
  catch (e) {
    return e
  }
}

async function cancelSubscriptionAPI(payload) {
  try {
    setAxiosAccessToken()
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/cancelSubscription', payload)
    return response
  }
  catch (e) {
    return e
  }
}

async function updateMongoCustomerAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/updateMongoCustomer', payload)
    return response
  }
  catch (e) {
    return e
  }
}

async function updatePaymentMethodAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/updatePaymentMethod', payload)
    return response
  }
  catch (e) {
    return e;
  }
}

async function passwordResetAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/passwordResetRequest', payload)
    return response
  }
  catch (e) {
    return e;
  }
}

async function registerGoogleAuthCodeAPI(payload) {
  try {
    setAxiosAccessToken();
    let response = await axios.post(process.env.REACT_APP_SERVER_URL+'/api/customer/registerGoogleAuthCode', payload);
    return response;
  }
  catch(e) {
    return e;
  }
}

const accountSaga = {
  getAccountData: function* (action) {
    let success = false;
    try {
      const response = yield call(getAccountDataAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      //console.log("response in saga", response);

      const subscriptions = _.get(response, 'data.subscriptions', [])
      const auth0Profile = _.get(response, 'data.auth0Profile', {})
      const auth0User = _.get(response, 'data.auth0User', {})
      const mongoCustomer = _.get(response, 'data.mongoCustomer', {})
      const defaultPaymentMethod = _.get(response, 'data.defaultPaymentMethod', null);
      const sites = _.get(response, 'data.sites', null);
      yield put({
        type: "GET_SITES_SUCCESS",
        normalized: normalize(sites, [sitesSchema])
      });
      yield put({
        type: "GET_SUBSCRIPTIONS_SUCCESS",
        normalized: normalize(subscriptions, [subscriptionSchema])
      });   
      yield put({
        type: "GET_ACCOUNT_DATA_SUCCESS",
        setKeys: [
          {key: "auth0Profile", value: auth0Profile},
          {key: "auth0User", value: auth0User},
          {key: "mongoCustomer", value: mongoCustomer},
          {key: 'defaultPaymentMethod', value: defaultPaymentMethod}
        ]
      });     
      if (action.onSuccess){
        action.onSuccess(response.data);
      }
    } catch (e) {
      success = false
    }

    if (!success) {
      yield put({ type: "GET_SUBSCRIPTIONS_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },
  
  logUserIn: function* (action) {
    let success = true
    try{
      yield put({
        type: "LOG_USER_IN_SUCCESS",
        payload: action.payload, 
      });
    }
    catch (e) {
      success = false
    }

    if (success && action.onSuccess){
      action.onSuccess()
    }
    else if (action.onFail){
      action.onFail()
    }

  },

  logUserOut: function* (action) {
    try{
      localStorage.clear();
      window.location = 'https://dev-ugoafngs.auth0.com/v2/logout?returnTo='+ process.env.REACT_APP_LOGOUT_URL
    }
    catch(e){
      console.log("logout error", e)
      yield put({ type: "USER_LOGOUT_FAIL", payload: {} })
    }
  },

  cancelSubscription: function* (action) {
    let success = false;
    try {
      const response = yield call(cancelSubscriptionAPI, action.payload)
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      //console.log("cancel subscription response in saga", response);
      const canceledSubscription = _.get(response, 'data.subscription', null)
      //console.log("canceledSubscription", canceledSubscription);
      if (canceledSubscription !== null){
        yield put({
          type: "CANCEL_SUBSCRIPTION_SUCCESS",
          normalized: normalize(canceledSubscription, subscriptionSchema)
        });
      }

      if (action.onSuccess){
        action.onSuccess()
      }
    } catch (e) {
      success = false;
    }

    if (!success) {
      yield put({ type: "CANCEL_SUBSCRIPTION_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },

  updateMongoCustomer: function* (action) {
    let success = false
    try {
      const response = yield call(updateMongoCustomerAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true
      }
      const updatedMongoCustomer = _.get(response, 'data.mongoCustomer', null)
      if (updatedMongoCustomer !== null){
        yield put({
          type: "UPDATE_MONGO_CUSTOMER_SUCCESS",
          normalized: normalize(updatedMongoCustomer, customerSchema)
        });
        if (action.self === true){
          yield put({
            type: "UPDATE_MONGO_CUSTOMER_SUCCESS",
            setKeys: [
              {key: "mongoCustomer", value: updatedMongoCustomer}
            ]
          });
        }
      }
      if (action.onSuccess){
        action.onSuccess()
      }
    } catch (e) {
      success = false;
    }

    if (!success) {
      yield put({ type: "UPDATE_MONGO_CUSTOMER_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },

  updatePaymentMethod: function* (action) {
    let success = false;
    try {
      const response = yield call(updatePaymentMethodAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      const defaultPaymentMethod = _.get(response, 'data.defaultPaymentMethod', null)
      if (defaultPaymentMethod !== null){
        yield put({
          type: "UPDATE_PAYMENT_METHOD_SUCCESS",
          setKeys: [
            {key: "defaultPaymentMethod", value: defaultPaymentMethod}
          ]
        })
      }

      if (action.onSuccess){
        action.onSuccess()
      }
    } catch (e) {
      success = false
    }

    if (!success) {
      yield put({ type: "UPDATE_PAYMENT_METHOD_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },

  passwordReset: function* (action) {
    let success = false;
    try {
      const response = yield call(passwordResetAPI, action.payload);
      if (response && response.status === 200 && response.statusText === "OK"){
        success = true;
      }
      if (action.onSuccess){
        action.onSuccess()
      }
    } catch (e) {
      success = false
    }

    if (!success) {
      yield put({ type: "UPDATE_PAYMENT_METHOD_FAIL", payload: {} })
      if (action.onFail) {
        action.onFail()
      }
    }
  },

  registerGoogleAuthCode: function* (action) {
    let success = false;
    try {
      console.log("Payload ", action.payload);
      yield put({ type: "REGISTER_GOOGLE_AUTH_CODE_SUCCESS" })
      const response = yield call(registerGoogleAuthCodeAPI, action.payload);
      if (response && response.status === 200 && response.data.success && action.onSuccess) {
        success = true;
      }
    } catch(e) {
      success = false;
    }
    
    if (success) {
      yield put({ type: "REGISTER_GOOGLE_AUTH_CODE_SUCCESS" })
      if (action.onSuccess) {
        action.onSuccess();
      }
    }
    else {
      yield put({ type: "REGISTER_GOOGLE_AUTH_CODE_FAIL" })
      if (action.onFail) {
        action.onFail()
      }
    }
  }
}

export default accountSaga;