import React from 'react';
// import logo from './logo.svg';
import {
    Page,
    Toolbar,
    ToolbarButton,
    Icon,
    SplitterSide,
    List,
    ListItem
} from 'react-onsenui';

class TenantDetail extends React.Component {

    render() {
        if (this.props.selectedTenant === null) {
            return (
                <SplitterSide
                    side="right"
                    width={850}
                    collapse={this.props.closeRight || 'portrait'}
                >
                    <Page></Page>
                </SplitterSide>
            )
        }
        const rowStyle = {
            height: 62
        }

        console.log("time", this.props.selectedTenant.current_period_end);
        //const renewal_date = new Date(this.props.selectedTenant.current_period_end * 1000)
        if (this.props.selectedTenant !== null) {
            return (
                <SplitterSide
                    side="right"
                    width={850}
                    collapse={this.props.closeRight || 'portrait'}
                >
                    <Page>
                        <Toolbar>
                            <div className="center">
                                {this.props.selectedTenant.id}
                            </div>
                            <div className="right">
                                <ToolbarButton>
                                    <Icon icon="md-more-vert" size={24} />
                                </ToolbarButton>
                            </div>
                        </Toolbar>
                        <br /><br />
                        <List>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Site Name</div>
                                <div style={rowStyle} className="right" >{this.props.selectedTenant.site_name}</div>
                            </ListItem>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Printers</div>
                                <div style={rowStyle} className="right" >{this.props.selectedTenant.printers}</div>
                            </ListItem>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Users</div>
                                <div style={rowStyle} className="right" >{this.props.selectedTenant.users}</div>
                            </ListItem>
                            <ListItem modifier="longdivider">
                                <div style={rowStyle} className="left" >Total Endpoints</div>
                                <div style={rowStyle} className="right" >{this.props.selectedTenanttotal_endpoints}</div>
                            </ListItem>
                        </List>

                    </Page>
                </SplitterSide>
            )
        }
    }
}

export default TenantDetail