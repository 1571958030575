/* global gapi */
import React from 'react';
import { connect } from "react-redux";
import LicenseDetails from './LicenseDetails'
import { getSiteData, updateSite } from '../../actions/siteActions'
import { cancelSubscription } from '../../actions/accountActions'
import { arrayFromKeyedObject } from '../../utils/dataUtils';
import { updateGoogleGroups } from '../../actions/customerActions';
import _ from 'lodash'
// import logo from './logo.svg';
import {
  Page,
  Toolbar,
  SplitterSide,
  SplitterContent,
  List,
  ListItem
} from 'react-onsenui';
import { productsMapping } from '../utils/products';

var GoogleAuth; // Google Auth object.
const discoveryUrl = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';
const SCOPE = 'https://www.googleapis.com/auth/admin.directory.group.readonly';
const axios = require('axios')

axios.interceptors.response.use(response => {
  return response;
}, error => {
  console.log("AXIOS Error", error);
  return error.response;
});

class License extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      closeRight: false,
      selectedLicenseId: null,
      singlePageListMode: true
    }
  }

  componentDidMount(){
    gapi.load('client:auth2', this.initClient);

    if (this.state.selectedLicenseId === null){
      if (this.props.subscriptions && this.props.subscriptions.length){
        this.setState({
          selectedLicenseId: this.props.subscriptions[0].id
        })
      }
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.navCount !== prevProps.navCount){
      this.setState({
        singlePageListMode: true
      })
    }
  }

  initClient = () => {
    // Retrieve the discovery document for version 3 of Google Drive API.

    // In practice, your app can retrieve one or more discovery documents.

    // Initialize the gapi.client object, which app uses to make API requests.
    // Get API key and client ID from API Console.
    // 'scope' field specifies space-delimited list of access scopes.

    gapi.client.init({
        'apiKey': process.env.REACT_APP_GAPI_APIKEY,
        'discoveryDocs': [discoveryUrl],
        'clientId': process.env.REACT_APP_GAPI_CLIENT_ID,
        'scope': SCOPE
    }).then(() => {
        GoogleAuth = gapi.auth2.getAuthInstance();

        // Listen for sign-in state changes.
        GoogleAuth.isSignedIn.listen(this.updateSigninStatus);

        // Handle initial sign-in state. (Determine if user is already signed in.)
        this._user = GoogleAuth.currentUser.get();
        console.log(this._user);
        this.setSigninStatus();
    });
}

  handleClientLoad = () => {
    // Load the API's client and auth2 modules.
    // Call the initClient function after the modules load.
    gapi.load('client:auth2', this.initClient);
}

signIn = (subscription_id) => {
  if (GoogleAuth){
      GoogleAuth.signIn().then((result) => {
          console.log("signed in");
          console.log(result);
          return result;
      }).then(() => {
        this.logUser();
        this.getGroups(subscription_id);
      });  
  }
}

signOut = () => {
  if (GoogleAuth){
      GoogleAuth.signOut();
  }
}

revokeAccess = () => {
  if (GoogleAuth){
      GoogleAuth.disconnect();
  }
}

setSigninStatus = (isSignedIn) => {
  if (GoogleAuth){
      var user = GoogleAuth.currentUser.get();
      var isAuthorized = user.hasGrantedScopes(SCOPE);
      if (isAuthorized) {

      } else {

      }
  }
}

updateSigninStatus = (isSignedIn) => {
  this.setSigninStatus();
}


async findNestedKey(o, key) {
  if( o[key] ){
    return o[key];
  }
  var result, p; 
  for (p in o) {
      if( o.hasOwnProperty(p) && typeof o[p] === 'object' ) {
          result = await this.findNestedKey(o[p], key);
          if(result){
              return result;
          }
      }
  }
  return result;
}

async getGroups(subscription_id) {
  console.log("FULL USER", this._user);
  console.log("calling getGroups findNestedKey")
  const access_token = await this.findNestedKey(this._user, 'access_token')
  console.log(access_token);
  if (access_token) {
      const config = {
          headers: {
              'Authorization': "Bearer " + access_token
          }
      }
      const URL = 'https://www.googleapis.com/admin/directory/v1/groups?key='+process.env.REACT_APP_GAPI_APIKEY+'&customer=my_customer'
      const result = await axios.get(URL, config);
      console.log(result);
      const groups = _.get(result, 'data.groups', null);
      if (groups) {
          console.log("groups found");
          for (let i=0; i<groups.length; i++){
              let members = await this.getUsersInGroup(groups[i]);
              groups[i].members = members;
          }
          console.log("groups found v1");
          console.log(groups);
          
          //this.props.updateGoogleGroups(data);
          // send to and overwright current mongo value
      }
      const data = {
          groups: groups,
          subscription_id: subscription_id
      }
      this.props.updateGoogleGroups(data);
  }
}

async getUsersInGroup(group) {
  console.log("FULL USER", this._user);
  console.log("calling getUsersInGroup findNestedKey")
  const access_token = await this.findNestedKey(this._user, 'access_token')
  console.log(access_token);
  if (access_token) {
      const config = {
          headers: {
              'Authorization': "Bearer " + access_token
          }
      }
      const URL = `https://www.googleapis.com/admin/directory/v1/groups/${group.id}/members?key=AIzaSyDlrVQTc4Xy1l-bC9pAAor8lnn5KqnOnus`
      const result = await axios.get(URL, config);
      console.log(result.data);
      const users = _.get(result, 'data.members', null);
      if (users) {
          return users;
          // get all users in each group by key
          // for (let i=0; i<users.length; i++){
          //     console.log("user found", users[i]);
          //     this.props.updateGoogleGroups({
          //         users: users[i], 
          //         group: group
          //     });
          //     // TODO have user and group info here ie group.name
          // }
          // console.log("groups found");
          //this.props.updateGoogleGroups(groups);
          // send to and overwright current mongo value
      }
      return [];
  }
}

logUser() {
  this._user = GoogleAuth.currentUser.get();
  console.log(this._user);
}

  handleSelectLicense = (license) => {
    console.log("selecting license", license);
    this.setState({
      selectedLicenseId: license.id,
      singlePageListMode: false
    })
  }

  resetFromNav = () => {
    this.setState({
      singlePageListMode: true
    })
  }


  directorySyncClicked = (row) => {
    console.log("directory sync time", row.id);
    // I need to save the subscription_id for checking it later
    
    localStorage.setItem("subscription_id", row.id)

    const redirect_uri = `${process.env.REACT_APP_URL}${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`
    const scope = ['https://www.googleapis.com/auth/admin.directory.group.readonly', 'https://www.googleapis.com/auth/admin.directory.group.member.readonly'];
    const url = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=openid email ${scope.join(' ')}`;
    window.location.assign(url);
  }

  renderLicenseRow = (row, idx) => {
    let selected = false;
    const site = this.props.sites.find(el => el.subscription_id === row.id);
    if (row.id === this.state.selectedLicenseId) {
      selected = true;
    }
    //const selectedStyle = selected ? { fontWeight: 'bold' } : {};
    const productName = productsMapping.hasOwnProperty(row.plan.product) ? productsMapping[row.plan.product].name : "Product not found"
    const showGoogleSync = productsMapping.hasOwnProperty(row.plan.product) ? productsMapping[row.plan.product].isPresto3 : false
    // console.log("Site ", site)

    return (
      <ListItem tappable={true} className={selected ? "selected" : ""} key={idx} modifier="longdivider" onClick={() => this.handleSelectLicense(row)}>
        <div>
          <b>Product: </b>
          <span>
            {
              productName
            }
          </span>
          <br />
          <b>License: </b>
          <span>{row.title}</span>
          <br />
          &nbsp;
        </div>
        {
          showGoogleSync ?
            (
              <div className="right">
                <span>
                  {
                    site && site.google_groups && site.google_groups.length > 0 ? (
                      <img style={{ height: 50 }} src="/google-sync-update.jpeg" />
                    ): (
                      <img onClick={() => this.directorySyncClicked(row)} style={{ height: 50 }} src="/directory_sync.png" />
                    )
                  }
                </span>
              </div>
            )  : null
        }
      </ListItem>
    )
  }

  renderLicenseList = () => {

    let filtered_subscriptions = this.props.subscriptions;
    filtered_subscriptions.forEach(x => {
      const site = this.props.sites.find(s => s.subscription_id === x.id);
      if (site && site.name){
        x.title = site.name
      }
      else{
        x.title = x.id
      }
    })
    if (this.props.search){
      filtered_subscriptions = filtered_subscriptions.filter(x => {
        if (x && x.title !== x.id){
          if (x.title.toLowerCase().includes(this.props.search.toLowerCase())){
            return x;
          }
        }
        else if (x.id.toLowerCase().includes(this.props.search.toLowerCase())){
          return x;
        }
        return false;
      })
    }

    return (
      <Page>
       <Toolbar>
          <div className="center title-bold">
              Subscriptions
          </div>
      </Toolbar>
      <br /><br />
      <List
        dataSource={filtered_subscriptions}
        renderRow={(row, idx) => (
          this.renderLicenseRow(row, idx)
        )}
        renderFooter={() => <div></div>}
      />
      </Page>

    )
  }

  renderLicense = () => {
    if (this.state.selectedLicenseId === null) {
      return (
        <Page>
        </Page>
      )
    }
    return (
      <LicenseDetails 
        updateSite={(subscription_id, data) => {this.props.updateSite(subscription_id, data, () => this.props.showToast("Site's Information has been updated successfully"))}}
        getSiteData={(subscription_id) => {this.props.getSiteData(subscription_id)}}
        cancelSubscription={(subscription_id) => {this.props.cancelSubscription(subscription_id, () => this.props.showToast("Subscription has been cancelled successfully"))}}
        closeRight={this.state.closeRight}
        selectedLicenseId={this.state.selectedLicenseId}
      />
    )
  }

  render() {

    if (this.props.browser.size === "large") {
      return (
        <React.Fragment>
          <SplitterContent >
            {this.renderLicenseList()}
          </SplitterContent>
          <SplitterSide
            side="right"
            width={600}
            collapse={false}
            isOpen={true}
          >
            <Page> {this.renderLicense()} </Page>
          </SplitterSide>
        </React.Fragment>
      )

    }
    else {
      return (
        <React.Fragment>
          <SplitterContent>
            {this.state.singlePageListMode ? this.renderLicenseList() : this.renderLicense()}
          </SplitterContent>
          <SplitterSide
            side="right"
            width={600}
            collapse={true}
            isOpen={false}
          >
            <Page></Page>
          </SplitterSide>
        </React.Fragment>
      )
    }
  }

}

const mapStateToProps = (state) => {
  const subscriptions = arrayFromKeyedObject(state.data.subscriptions);
  const sites = arrayFromKeyedObject(state.data.sites);
  return {
    logged_in: state.data.logged_in,
    subscriptions: subscriptions,
    tenants: state.data.tenants,
    sites: sites
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSiteData: (subscription_id, onSuccess, onFail) => {
      dispatch(getSiteData(subscription_id, onSuccess, onFail))
    },
    updateSite: (subscription_id, data, onSuccess, onFail) => {
      dispatch(updateSite(subscription_id, data, onSuccess, onFail))
    },
    cancelSubscription: (subscription_id, onSuccess, onFail) => {
      dispatch(cancelSubscription(subscription_id, onSuccess, onFail))
    },
    updateGoogleGroups: (data, onSuccess, onFail) => {
      dispatch(updateGoogleGroups(data, onSuccess, onFail))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(License);
